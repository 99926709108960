import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGripLinesVertical,
  faGripVertical,
} from "@fortawesome/pro-solid-svg-icons";

function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });

  //   console.log({ attributes, listeners, setNodeRef, transform, transition });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div style={style} className="sortable-item">
      {props.children}
      <div
        ref={setNodeRef}
        className={`handle`}
        {...attributes}
        {...listeners}
        style={{ position: "absolute" }}
      >
        <FontAwesomeIcon icon={faGripVertical} />
      </div>
    </div>
  );
}

export default SortableItem;
