import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSessionContext } from "./AdminSessionContext";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";

const AdminControls = () => {
  const { sendNextCard } = useSessionContext();
  return (
    <>
      <button
        className="button large full next-card"
        onClick={() => sendNextCard()}
      >
        Next Card
        <FontAwesomeIcon icon={faArrowRight} size="2x" />
      </button>
    </>
  );
};

export default AdminControls;
