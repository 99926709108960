import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getAuth } from "firebase/auth";
// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyBkCxBGbm4XRNoc2pqf15WAjl9rUO6Ng2U",
  authDomain: "hoverbot-gpt.firebaseapp.com",
  projectId: "hoverbot-gpt",
  storageBucket: "hoverbot-gpt.appspot.com",
  messagingSenderId: "1017690185037",
  appId: "1:1017690185037:web:a3376b928bc41b4658eb04",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const functions = getFunctions(app);
export const auth = getAuth(app);

export default app;
