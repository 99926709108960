import { scaleLog } from "@visx/scale";
import { Text } from "@visx/text";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import React from "react";
import { compareArrays } from "../util/compareArrays";

function wordFreq(words) {
  // const words = text.toLowerCase().replace(/\./g, ""); //.split(/\s/);
  const freqMap = {};

  for (const w of words) {
    if (!freqMap[w]) freqMap[w] = 0;
    freqMap[w] += 1;
  }
  return Object.keys(freqMap).map((word) => ({
    text: word,
    value: freqMap[word],
  }));
}

function getRotationDegree() {
  const rand = Math.random();
  const degree = rand > 0.5 ? 60 : -60;
  return rand * degree;
}

const fixedValueGenerator = () => 0.5;

const WordcloudOutput = (props) => {
  if (!props.words) return null;
  console.log({ props });
  const words = wordFreq(props.words);
  const fontScale = scaleLog({
    domain: [
      Math.min(...words.map((w) => w.value)),
      Math.max(...words.map((w) => w.value)),
    ],
    range: [50, 100],
  });
  const fontSizeSetter = (datum) => fontScale(datum.value);
  return (
    <Wordcloud
      words={words}
      rotate={0}
      height={320}
      width={640}
      fontSize={fontSizeSetter}
    >
      {(cloudWords) =>
        cloudWords.map((w, i) => (
          <>
            {/* {w.text} */}
            <Text
              key={w.text}
              fill={"currentColor"}
              textAnchor={"middle"}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          </>
        ))
      }
    </Wordcloud>
  );
};
export default React.memo(WordcloudOutput);
