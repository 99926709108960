import { AnimatePresence, motion } from "framer-motion";
import { useSessionContext } from "./AdminSessionContext";
import Card from "./Card";
import CardLoader from "./CardLoader";
import Output from "./viewer/Output";
import Viewer from "./viewer/Viewer";
import classNames from "classnames";
import Responses from "./Responses";
import {
  faArrowDown,
  faArrowRight,
  faChartBar,
  faCloud,
  faComment,
  faComments,
  faDownLeftAndUpRightToCenter,
  faEmptySet,
  faLightbulb,
  faList,
  faMessageLines,
  faThumbsUp,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import CardContentEditor from "./CardContentEditor";
import * as Ariakit from "@ariakit/react";

const AdminLive = () => {
  const {
    sessionId,
    cards,
    sessionData,
    updateCard,
    sendCard,
    launchCardEditor,
    deactivateCard,
  } = useSessionContext();
  const params = useParams();
  const [mobileNav, setMobileNav] = useState("settings");
  const [previewSize, setPreviewSize] = useState("full");
  const activeCardId = params.cardId
    ? params.cardId
    : sessionData.activeCards?.[0];
  const activeCard = cards.find((card) => card.id === activeCardId);
  // const activeCard = sessionData.activeCards[0];

  const isLiveCard = activeCardId === sessionData.activeCards?.[0];

  console.log({ activeCards: sessionData.activeCards, activeCardId });
  return (
    <div className="admin-live-wrapper" data-mobile-nav={mobileNav}>
      <div
        className={classNames(
          "preview-wrapper",
          previewSize === "full" ? "preview-full" : "preview-small"
        )}
      >
        <div className="preview-wrapper-toggle">
          <button
            className=" button transparent"
            onClick={() =>
              setPreviewSize(previewSize === "full" ? "small" : "full")
            }
          >
            <FontAwesomeIcon
              icon={
                previewSize === "full"
                  ? faDownLeftAndUpRightToCenter
                  : faUpRightAndDownLeftFromCenter
              }
            />
            <div className="h3">Show preview</div>
          </button>
        </div>
        <div className="output-wrapper">
          {/* <Output showHeader={false} /> */}
          <div
            className={classNames([
              "output-screen",
              sessionData?.theme ? sessionData.theme : "theme-default",
            ])}
          >
            <motion.div className={classNames(["output-cards"])}>
              <AnimatePresence mode="popLayout">
                {activeCard &&
                  [activeCard].map((card, index) => (
                    <motion.div
                      key={card.id}
                      className="output-card-wrapper"
                      layout="position"
                      // style={{ position: "relative" }}
                      initial={{ x: 50, opacity: 0 }}
                      animate={{ x: 0, opacity: 1, zIndex: 10 }}
                      exit={{ x: -150, opacity: 0, scale: 0.9, zIndex: 5 }}
                      transition={{ duration: 1, ease: "easeInOut" }}
                    >
                      <Card
                        key={index}
                        output={true}
                        card={{ ...card, sessionId }}
                        noninteractive
                      />
                    </motion.div>
                  ))}
              </AnimatePresence>
            </motion.div>
          </div>
        </div>
        <div
          className={classNames("viewer-wrapper", [
            sessionData?.theme ? sessionData.theme : "theme-default",
          ])}
        >
          <div className={classNames(["viewer"])}>
            <motion.div className={classNames(["card-list"])}>
              <AnimatePresence mode="popLayout">
                {(activeCardId &&
                  [activeCardId].map((id) => (
                    <CardLoader key={id} cardId={id} />
                  ))) || (
                  <div className="card-wrapper rejected">
                    <Card
                      card={{
                        headline: "No active cards",
                      }}
                    />
                  </div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="admin-mobile-live-tabs tabs toolbar">
        <div
          className={classNames("tab", { active: mobileNav === "settings" })}
          onClick={() => setMobileNav("settings")}
        >
          Settings
        </div>
        <div
          className={classNames("tab", { active: mobileNav === "responses" })}
          onClick={() => setMobileNav("responses")}
        >
          Responses
        </div>
      </div>
      <div className="admin-responses-wrapper" data-mobile-content="responses">
        <Responses sessionId={sessionId} cardId={activeCardId} />
      </div>
      <div className="admin-card-preview-controls">
        {/* {isLiveCard ? null : ( */}

        <>
          <div className="button-row banner warning">
            {!isLiveCard ? (
              <span className="badge warning-bg">Preview</span>
            ) : null}
            <span>{activeCard?.headline}</span>
            <button
              className="button info"
              onClick={() => {
                launchCardEditor(activeCard);
              }}
              buttonType="button"
            >
              Edit
            </button>

            {isLiveCard ? (
              <button
                className="button warning"
                onClick={() => {
                  deactivateCard(activeCardId);
                }}
              >
                Hide card
              </button>
            ) : (
              <>
                <button
                  className="button success"
                  onClick={() => {
                    sendCard(activeCardId);
                  }}
                  buttonType="button"
                >
                  Show card
                </button>
                <span style={{ width: "100%" }} />
                <Link className="button secondary" to="../live">
                  Close
                </Link>
              </>
            )}
          </div>
        </>
        {/* )} */}
      </div>
      <div
        className="admin-card-controls admin-panels"
        data-mobile-content="settings"
      >
        {activeCard?.cardType && (
          <>
            {activeCard?.cardType.match(/poll|prompt/) && (
              <div className="panel">
                <h3>Results Shown</h3>
                <div className="tiles scroll">
                  {[
                    { name: "none", label: "None", icon: faEmptySet },
                    { name: "all", label: "All", icon: faComments },
                    { name: "approved", label: "Approved", icon: faThumbsUp },
                  ].map((option) => (
                    <button
                      onClick={async () => {
                        const result = await updateCard(activeCardId, {
                          settings: { outputResultsShown: option.name },
                        });
                        console.log({ result });
                      }}
                      className={classNames("button tile", {
                        active:
                          activeCard?.settings?.outputResultsShown ===
                          option.name,
                        simple:
                          activeCard?.settings?.outputResultsShown !==
                          option.name,
                      })}
                    >
                      <FontAwesomeIcon icon={option.icon} size="2xl" />
                      <div>{option.label}</div>
                    </button>
                  ))}
                </div>
              </div>
            )}
            {activeCard?.cardType.match(/trivia/) && (
              <div className="panel">
                <h3>Answer Shown</h3>
                <div className="tiles scroll">
                  {[
                    { name: "none", label: "Hide", icon: faEmptySet },
                    { name: "all", label: "Show", icon: faComments },
                    {
                      name: "all-answers-in",
                      label: "All Answers In",
                      icon: faComments,
                    },
                  ].map((option) => (
                    <button
                      onClick={async () => {
                        const result = await updateCard(activeCardId, {
                          settings: { outputResultsShown: option.name },
                        });
                        console.log({ result });
                      }}
                      className={classNames("button tile", {
                        active:
                          activeCard?.settings?.outputResultsShown ===
                          option.name,
                        simple:
                          activeCard?.settings?.outputResultsShown !==
                          option.name,
                      })}
                    >
                      <FontAwesomeIcon icon={option.icon} size="2xl" />
                      <div>{option.label}</div>
                    </button>
                  ))}
                </div>
              </div>
            )}
            {activeCard?.cardType.match(/poll|prompt|trivia/) && (
              <div className="panel">
                <h3>Output View</h3>
                <div className="tiles scroll">
                  {[
                    { name: "none", label: "None", icon: faEmptySet },
                    {
                      name: "single",
                      label: "One at a time",
                      icon: faMessageLines,
                    },
                    { name: "list", label: "List", icon: faList },
                    {
                      name: "horizontal-bars",
                      label: "Horizontal Bars",
                      icon: faChartBar,
                    },
                    { name: "word-cloud", label: "Word cloud", icon: faCloud },
                    { name: "summary", label: "AI summary", icon: faLightbulb },
                  ].map((option) => (
                    <button
                      key={option.name}
                      onClick={async () => {
                        const result = await updateCard(activeCardId, {
                          settings: { outputViewMode: option.name },
                        });
                        console.log({ result });
                      }}
                      className={classNames("button tile", {
                        active:
                          activeCard?.settings?.outputViewMode === option.name,
                        simple:
                          activeCard?.settings?.outputViewMode !== option.name,
                      })}
                    >
                      <FontAwesomeIcon icon={option.icon} size="2xl" />
                      <div>{option.label}</div>
                    </button>
                  ))}
                </div>
              </div>
            )}
            {activeCard?.cardType.match(/poll|prompt|signup|big-list/) && (
              <div className="panel">
                <h3>How many interactions per participant?</h3>
                <div className="tiles scroll">
                  {[
                    { name: "dismiss", label: "Once", icon: faComment },
                    { name: "resubmit", label: "Multiple", icon: faComments },
                  ].map((option) => (
                    <button
                      key={option.name}
                      onClick={async () => {
                        const result = await updateCard(activeCardId, {
                          settings: { afterUserInteraction: option.name },
                        });
                        console.log({ result });
                      }}
                      className={classNames("button tile", {
                        active:
                          activeCard?.settings?.afterUserInteraction ===
                          option.name,
                        simple:
                          activeCard?.settings?.afterUserInteraction !==
                          option.name,
                      })}
                    >
                      <FontAwesomeIcon icon={option.icon} size="2xl" />
                      <div>{option.label}</div>
                    </button>
                  ))}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default AdminLive;
