import {
  collection,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useSessionContext } from "./AdminSessionContext";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const ContentLibrary = () => {
  const [libraryEntries, setLibraryEntries] = useState([]);
  const { addContentLibraryDoc, sessionId } = useSessionContext();

  useEffect(() => {
    if (!sessionId) return; // Don't run if sessionId is not set

    const libraryQuery = query(
      collection(db, "contentLibrary"),
      where("sessionId", "==", sessionId)
    );
    // const snap = getDocs(libraryQuery);
    return onSnapshot(libraryQuery, (snapshot) => {
      console.log("library snapshot", snapshot.docs);
      setLibraryEntries(
        snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
      );
    });
  }, [sessionId]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    addContentLibraryDoc({ name: data.name, content: data.content });
  };

  return (
    <div
      style={{ display: "grid", gridTemplateColumns: "20em 1fr", gap: "1em" }}
    >
      <div className="sidebar">
        {/* <form onSubmit={handleSubmit(onSubmit)}>
          <label>
            Library Entry Name:
            <input {...register("name")} />
          </label>
          <label>
            Library Entry Content:
            <textarea {...register("content")} />
          </label>
          <label>
            File:
            <input type="file" {...register("file")} />
          </label>
          <input type="submit" />
        </form> */}
        <a
          href={`https://cloudrun-xtkazthhcq-uc.a.run.app/selectPresentation?sessionId=${sessionId}`}
          target="_blank"
          className="button large"
          rel="noopener noreferrer"
        >
          Import from Google Slides
        </a>
      </div>
      <div>
        {libraryEntries.map((entry) => (
          <div key={entry.id} className="card">
            <h1>{entry.name}</h1>
            <small>{"{{content(" + entry.id + ")}}"}</small>
            <p style={{ maxHeight: 200 }}>{entry.content}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContentLibrary;
