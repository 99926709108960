import { useState, useEffect } from "react";
import { db } from "../firebase";
import { collection, onSnapshot } from "firebase/firestore";

const useCardDeck = (deckId) => {
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (!deckId) return;
    const unsubscribe = onSnapshot(
      collection(db, "cardDecks", deckId || "____", "cards"),
      (snapshot) => {
        console.log("usecardDeck", { deckId, snapshot });
        const cardsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCards(cardsData);
      }
    );
    return unsubscribe;
  }, [deckId]);

  return cards;
};

export default useCardDeck;
