import { useFirebaseAuth } from "../../contexts/AuthProvider";
import { auth } from "../../firebase";

const ViewerLoggedIn = () => {
  const { user, displayName } = useFirebaseAuth();

  if (!user) return null;
  return (
    <div className="viewer-logged-in">
      <div>{displayName || "(anon)"}</div>
      <button
        className="button"
        onClick={() => auth.signOut().then(() => window.location.reload())}
      >
        Log out
      </button>
    </div>
  );
};

export default ViewerLoggedIn;
