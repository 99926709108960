import { useSearchParams } from "react-router-dom";
import { useSessionContext } from "./AdminSessionContext";
import { cardTypes } from "./CardTypePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeadSideBrain,
  faListCheck,
  faPencil,
  faPoll,
  faPollH,
  faRectangle,
  faSignature,
  faUsersBetweenLines,
  faWatch,
} from "@fortawesome/pro-solid-svg-icons";
import CardGallery from "./CardGallery";
import Card from "./Card";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  EffectCreative,
  FreeMode,
  Navigation,
  Pagination,
} from "swiper/modules";

const icons = {
  poll: faPollH,
  prompt: faPencil,
  "big-list": faListCheck,
  signup: faSignature,
  simple: faRectangle,
  break: faWatch,
  lobby: faUsersBetweenLines,
  trivia: faHeadSideBrain,
};

const NewCardModal = () => {
  const { sessionId, createCard } = useSessionContext();
  let [searchParams, setSearchParams] = useSearchParams();

  const sampleCards = cardTypes.reduce(
    (acc, cardType) => [...acc, ...(cardType?.sampleData || [])],
    []
  );

  console.log({ sampleCards });

  return (
    <div className="new-card-modal admin-panels">
      <div className="panel">
        <h1>Create a new card</h1>
        <div className="tile-grid">
          {cardTypes.map((cardType) => {
            return (
              <div
                className="button tile"
                onClick={async () => {
                  // setSearchParams({ type: cardType.cardType });
                  const newCard = await createCard({
                    cardType: cardType.cardType,
                    sessionId,
                  });
                  setSearchParams({ action: "edit", cardId: newCard.id });
                }}
              >
                <FontAwesomeIcon
                  icon={icons[cardType.cardType]}
                  size="xl"
                  fixedWidth
                />
                <span>{cardType.name}</span>
                {/* <h2>{cardType.name}</h2>
            <p>{cardType.description}</p> */}
              </div>
            );
          })}
        </div>
      </div>
      <div className="panel">
        <h1>Suggestions</h1>
        <div className="suggested-cards">
          <div className={classNames(["card-stack"])}>
            <Swiper
              // effect={"creative"}
              slidesPerView={"auto"}
              spaceBetween={10}
              freeMode={true}
              modules={[FreeMode, Pagination, Navigation]}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              navigation={true}
              height={100}
              // creativeEffect={{
              //   prev: {
              //     shadow: false,
              //     translate: [0, 0, -400],
              //     opacity: 0,
              //   },
              //   next: {
              //     translate: ["100%", 0, 0],
              //   },
              // }}
              grabCursor={true}
            >
              {sampleCards.map((card) => (
                <SwiperSlide className="suggested-card">
                  <Card card={card} noninteractive />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCardModal;
