import { Button } from "@ariakit/react";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createPortal } from "react-dom";
import { useParams, useSearchParams } from "react-router-dom";
import CardEditor from "./CardEditor";
import CardTypePicker, { cardTypes } from "./CardTypePicker";
import { useSessionContext } from "./AdminSessionContext";
import NewCardModal from "./NewCardModal";

const CardEditorPortal = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  console.log("Portal rendering");

  return (
    searchParams.get("action") &&
    createPortal(
      <div
        className="portal-backdrop backdrop"
        onClick={(e) => {
          console.log({ e });
          if (e.target === e.currentTarget && searchParams.has("action")) {
            searchParams.delete("action");
            setSearchParams({});
          }
        }}
      >
        <div
          className="portal"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Button
            className="button transparent close"
            onClick={() => {
              if (searchParams.has("action")) {
                searchParams.delete("action");
                setSearchParams({});
              }
            }}
          >
            <FontAwesomeIcon icon={faTimes} size="xl" />
          </Button>
          {searchParams.get("action") === "new" && <NewCardModal />}
          {searchParams.get("action") === "edit" &&
            searchParams.get("cardId") && (
              <CardEditor
                cardId={searchParams.get("cardId")}
                closePortal={() => {
                  if (searchParams.has("action")) {
                    searchParams.delete("action");
                    setSearchParams({});
                  }
                }}
              />
            )}
        </div>
      </div>,
      document.body,
      searchParams.get("edit") + searchParams.get("new")
    )
  );
};
export default CardEditorPortal;
