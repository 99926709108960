import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import Card from "./Card";
import { Button } from "@ariakit/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "react-tooltip";

const CardDeckPreview = ({ deckId }) => {
  const [deckData, setDeckData] = useState({});
  const [cards, setCards] = useState([]);

  const cardsCollection = collection(db, "cardDecks", deckId, "cards");
  useEffect(() => {
    onSnapshot(cardsCollection, async (snapshot) => {
      const cardsList = snapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCards(cardsList);
    });
  }, [deckId]);

  useEffect(() => {
    getDoc(doc(db, "cardDecks", deckId))
      .then((doc) => {
        console.log({ doc });
        if (doc.exists()) {
          setDeckData({ ...doc.data(), id: doc.id });
        } else {
          console.log("No doc");
        }
      })
      .catch(console.error);
  }, [deckId]);

  return (
    <div className="card-deck-preview">
      <div>
        <h1 className="heading">{deckData?.name ?? "Untitled"}</h1>
        <p>{deckData?.description ?? ""}</p>
      </div>

      <div className="cards">
        {cards.map((card) => {
          return (
            <div key={card.id}>
              <Card noninteractive outline card={card} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default CardDeckPreview;
