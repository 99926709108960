import {
  faCheckCircle,
  faPlusCircle,
  faTimesCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

const EditableOptions = (props) => {
  const { editable, renderEditableText } = props;
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const optionsFields = useFieldArray({
    name: "options",
  });

  return (
    <div className="options">
      {(optionsFields?.fields || props.card.options).map((option, i) => (
        <div
          key={option.id ?? option.text ?? i}
          className={`option ${
            option.text === selectedAnswer ? "selected" : ""
          }`}
        >
          {/* <span>{option.text}</span> */}
          <span className="option-text">
            {renderEditableText({
              name: `options.${i}.text`,
              value: option.text,
              placeholder: "Write an option",
            })}
          </span>
          {editable && (
            <div className="editable-controls">
              <div
                onClick={() => {
                  optionsFields.remove(i);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  style={{ opacity: 0.2, ":hover": { opacity: 1 } }}
                />
              </div>
              {props.card.cardType === "trivia" && (
                <div
                  onClick={() => {
                    optionsFields.update(i, {
                      ...option,
                      correct: !option.correct,
                    });
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{ opacity: option.correct ? 1 : 0.2 }}
                  />
                </div>
              )}
            </div>
          )}
          {selectedAnswer && props.card.type === "trivia" ? (
            <>
              {option.correct ? (
                <span className="correct-icon">✔</span>
              ) : (
                <span className="incorrect-icon">👎</span>
              )}
            </>
          ) : (
            <>
              {option.text === selectedAnswer ? (
                <span className="vote-icon">✔</span>
              ) : null}
            </>
          )}
        </div>
      ))}
      {editable &&
        (props.card.cardType === "trivia" || props.card.cardType === "poll") &&
        optionsFields && (
          <button
            className="button success icon"
            onClick={() => {
              console.log({ optionsFields });
              optionsFields.append({ text: "" });
            }}
            type="button"
          >
            <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: 4 }} />
            Add option
          </button>
        )}
    </div>
  );
};
export default EditableOptions;
