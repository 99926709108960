import React, { useState, useEffect } from "react";

const CountUpTimer = ({ startTime, endTime }) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    // If endTime is provided, calculate the difference and set it
    if (endTime) {
      setElapsedTime((endTime - startTime) / 1000);
      return;
    }

    // Function to update the elapsed time
    const updateElapsedTime = () => {
      const now = new Date().getTime();
      const timeElapsed = (now - startTime) / 1000;
      setElapsedTime(timeElapsed.toFixed(1));
    };

    // Update immediately
    updateElapsedTime();

    // Set an interval to update every 100ms
    const interval = setInterval(updateElapsedTime, 100);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [startTime, endTime]);

  return <div>{elapsedTime} seconds</div>;
};

export default CountUpTimer;
