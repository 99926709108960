import classNames from "classnames";
import { useSessionContext } from "./AdminSessionContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import UserLoader from "./UserLoader";

const Participant = ({ user, participated }) => {
  return (
    <div
      className={classNames(
        "participant",
        "user-thumbnail",
        participated ? "participated" : "not-participated"
      )}
    >
      {user?.displayName?.split(" ").map((word) => word[0])}
    </div>
  );
};

const AdminParticipation = ({ cardId, participantIds }) => {
  const adminSession = useSessionContext();

  const { sessionData } = adminSession;

  console.log({ participantIds });

  const totalUsers = sessionData?.activeUsers?.length || 0;
  const uniqueParticipants = participantIds.filter(
    (value, index, array) => array.indexOf(value) === index
  ).length;
  const participationRate = uniqueParticipants / totalUsers;

  return (
    <div className="admin-participation">
      <div className="participation-stats">
        <span>
          {uniqueParticipants} / {totalUsers}
        </span>
        <span>{totalUsers > 0 ? Math.round(participationRate * 100) : 0}%</span>
      </div>
      {sessionData?.activeUsers?.map((userId) => (
        <UserLoader userId={userId}>
          {/* {participantIds.includes(userId) ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : null} */}
          <Participant participated={participantIds.includes(userId)} />
        </UserLoader>
      ))}
    </div>
  );
};

export default AdminParticipation;
