import {
  faCheckCircle,
  faPlus,
  faPlusCircle,
  faTimes,
  faTimesCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import CountdownProgressBar from "./CountdownProgressBar";
import { Controller, useFieldArray } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "classnames";
import PromptForm from "./PromptForm";
import BigListDisplay from "./BigListDisplay";
import BigListGuessForm from "./BigListGuessForm";
import PromptOutput from "./PromptOutput";
import Options from "./Options";
import EditableOptions from "./EditableOptions";
import PollOutput from "./PollOutput";
import LobbyOutput from "./LobbyOutput";
import OutputOptionsGrid from "./OutputOptionsGrid";
import TriviaOutput from "./TriviaOutput";

const USER_TYPES = {
  CLICKABLE: "clickable",
  CHAT: "chat",
};

const Card = (props) => {
  // const [submitted, setSubmitted] = useState(false);
  const { onClick } = props;

  const editable = props.editable;

  const userType = props.output ? USER_TYPES.CHAT : USER_TYPES.CLICKABLE;
  // console.log({ control: props.control || {} });

  if (!props.card) return null;

  if (props.view === "condensed") {
    return (
      <div
        key={props.card?.id}
        className="card condensed"
        style={{}}
        data-card-type={props.card.cardType}
      >
        {props.card.headline && <h1>{props.card.headline}</h1>}
      </div>
    );
  }

  const renderEditableText = ({
    name,
    value,
    nonEditable,
    placeholder,
    className,
    wrapperClass,
  }) => {
    return editable ? (
      <div className={wrapperClass}>
        <Controller
          name={name}
          control={props.control}
          defaultValue={value}
          render={({ field }) => (
            <TextareaAutosize
              className={classNames("editable", "textarea", className)}
              placeholder={placeholder}
              {...field}
            />
          )}
        />
      </div>
    ) : (
      nonEditable || value
    );
  };

  if (props.userState?.submitted) {
    return (
      <div
        key={props.card?.id}
        className={classNames("card", {
          noninteractive: props.noninteractive,
          clickable: props.clickable,
        })}
        editable={editable}
        style={{}}
        data-card-type={"confirmation"}
        onClick={onClick}
        ref={props.ref}
        data-no-dnd="true"
      >
        <div className="big-icon-wrapper">
          <FontAwesomeIcon icon={faCheckCircle} size="3x" />
        </div>
        <div>
          {renderEditableText({
            name: "confirmationText",
            value: props.card.confirmationText,
            placeholder: "Add confirmation text",
          })}
        </div>
      </div>
    );
  }

  if (props.card.cardType === "break") return null;

  return (
    <div
      key={props.card?.id}
      className={classNames(
        "card",
        {
          noninteractive: props.noninteractive,
          clickable: props.clickable,
          outline: props.outline,
        },
        props.className
      )}
      editable={editable}
      data-card-type={props.card.cardType}
      onClick={onClick}
    >
      {props.dogear && (
        <div className="dogear">
          <button> </button>
        </div>
      )}
      {props.card.duration && (
        <CountdownProgressBar duration={props.card.duration} />
      )}
      {props.card.dismissable && (
        <div className="dismiss-button-wrapper">
          <button
            className="button transparent dismiss"
            // onClick={() => setSelected(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
      <h1>
        {renderEditableText({
          name: "headline",
          value: props.card.headline,
          placeholder: "Add a headline",
        })}
      </h1>
      <p>
        {renderEditableText({
          name: "text",
          value: props.card.text,
          placeholder: "Add additional text?",
        })}
      </p>
      {props.card.cardType === "lobby" && <LobbyOutput />}
      {props.card.cardType === "prompt" &&
        (userType === USER_TYPES.CHAT ? (
          <PromptOutput card={props.card} />
        ) : (
          <PromptForm
            card={props.card}
            renderEditableText={renderEditableText}
          />
        ))}
      {props.card.cardType === "big-list" && (
        <>
          <div className="big-list-wrapper">
            <BigListDisplay
              card={props.card}
              renderEditableText={renderEditableText}
              editable={editable}
            />
            {props.output && <PromptOutput card={props.card} max={3} />}
            {userType === USER_TYPES.CLICKABLE && (
              <BigListGuessForm
                card={props.card}
                afterSubmit={() => {
                  // setSubmitted(true);
                }}
                renderEditableText={renderEditableText}
                editable={editable}
              />
            )}
          </div>
        </>
      )}
      {props.card.cardType === "signup" &&
        (userType === USER_TYPES.CHAT ? null : (
          <div className="form signup-form">
            <input className="input" placeholder="Email address" />
            <button className="button">Sign Up</button>
          </div>
        ))}
      {props.card.cardType === "poll" && (
        <>
          {userType === USER_TYPES.CHAT ? (
            <PollOutput card={props.card} />
          ) : (
            <>
              {props.editable ? (
                <EditableOptions
                  card={props.card}
                  editable={props.editable}
                  renderEditableText={renderEditableText}
                />
              ) : (
                <Options
                  card={props.card}
                  editable={props.editable}
                  renderEditableText={renderEditableText}
                />
              )}
            </>
          )}
        </>
      )}
      {props.card.cardType === "trivia" && (
        <>
          {userType === USER_TYPES.CHAT ? (
            <TriviaOutput card={props.card} />
          ) : (
            <>
              {props.editable ? (
                <EditableOptions
                  card={props.card}
                  editable={props.editable}
                  renderEditableText={renderEditableText}
                />
              ) : (
                <Options
                  card={props.card}
                  editable={props.editable}
                  renderEditableText={renderEditableText}
                />
              )}
            </>
          )}
        </>
      )}
      {props.card.button && <button>{props.card.button}</button>}
    </div>
  );
};
export default Card;
