import { SortableContext } from "@dnd-kit/sortable";
import { useSessionContext } from "./AdminSessionContext";
import SortableItem from "./SortableItem";
import CardLoader from "./CardLoader";
import classNames from "classnames";
import { useState } from "react";
import { Masonry } from "masonic";

const viewModeOptions = [
  "grid",
  "medium-grid",
  "short-grid",
  "condensed",
  "list",
  "list-condensed",
  "list-horizontal",
  "masonry",
  "detailed",
];

const CardGallery = ({
  cards,
  id,
  deckId,
  buildControls,
  onClick,
  showViewModePicker = true,
  ...props
}) => {
  const { sessionData, launchCardEditor } = useSessionContext();
  const [viewMode, setViewMode] = useState(props.viewMode || "grid");

  const items = cards || sessionData?.cardOrder || [];
  console.log("Hello", { cards });
  // console.log("Card Gallery", {
  //   sessionData,
  //   mapped: items.map((id) => ({ id })),
  // });

  const RenderCard = ({ id, ...props }) => {
    console.log("RenderCard", { id });
    const cardId = id.id ? id.id : id;
    return (
      <div data-no-dnd className="card-gallery-card-wrapper" key={cardId}>
        <CardLoader
          key={cardId}
          card={id.id ? id : undefined}
          deckId={deckId}
          cardId={cardId}
          onClick={
            onClick ??
            (() => {
              launchCardEditor({ id: cardId });
            })
          }
          view={viewMode === "list-condensed" ? "condensed" : viewMode}
          showControls
          noninteractive
          clickable
          buildControls={buildControls}
          outline
          {...props}
        />
      </div>
    );
  };

  return (
    <div className={classNames("card-gallery", viewMode)}>
      {showViewModePicker && (
        <div className="view-mode-picker">
          <span>View:</span>
          {viewModeOptions.map((option) => (
            <button
              key={option}
              className={classNames("button", "simple", {
                success: viewMode === option,
              })}
              onClick={() => setViewMode(option)}
            >
              {option}
            </button>
          ))}
        </div>
      )}
      {viewMode === "detailed" && (
        <div className="detailed-list">
          {items &&
            items.map((id) => (
              <div key={id} className="row">
                {/* <div className="output-wrapper"> */}
                <div className="output preview-output">
                  <div className="output-card-wrapper">
                    <RenderCard
                      id={id}
                      output={true}
                      noninteractive
                      showControls={false}
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div className="viewer-preview">
                  <RenderCard id={id} showControls={false} />
                </div>
              </div>
            ))}
        </div>
      )}
      {viewMode === "masonry" && (
        <Masonry
          columnWidth={320}
          items={items.map((id) => ({ id }))}
          render={({ data: { id } }) => <RenderCard id={id} />}
        />
      )}
      {!viewMode.match(/masonry|detailed/) && (
        <SortableContext
          id={id}
          items={items}
          // strategy={horizontalListSortingStrategy}
        >
          <div className="card-gallery-cards">
            {items && items.map((id) => <RenderCard id={id} />)}
          </div>
        </SortableContext>
      )}
    </div>
  );
};

export default CardGallery;
