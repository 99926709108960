import { useForm } from "react-hook-form";
import { useViewerSessionContext } from "../contexts/ViewerSessionContext";

const PromptForm = ({ card, afterSubmit, renderEditableText }) => {
  const viewerSessionContext = useViewerSessionContext();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    console.log(card, data);
    viewerSessionContext?.sendInteraction &&
      (await viewerSessionContext.sendInteraction(card.sessionId, card.id, {
        interactionType: "prompt",
        ...data,
        card,
      }));
    viewerSessionContext
      ? viewerSessionContext.addSystemCard(
          {
            cardType: "confirmation",
            headline: card.confirmationText || "Thank you!",
          },
          { timer: 5000 }
        )
      : console.log("no viewer session context");
    reset();
    afterSubmit && afterSubmit(data, card);
  };

  return (
    <form className="form prompt-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        {renderEditableText({
          name: "promptPlaceholder",
          value: card.promptPlaceholder,
          placeholder: "Type your response...",
          // className: "fake-textarea fake-input",
          wrapperClass: "textarea",
          nonEditable: (
            <textarea
              placeholder="Type your response..."
              {...register("response")}
              onKeyDown={(e) => {
                console.log(e);
                if (e.key === "Enter" && !e.shiftKey) {
                  handleSubmit(onSubmit)();
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            />
          ),
        })}
      </div>
      {renderEditableText({
        name: "submitButtonLabel",
        value: card.submitButtonLabel,
        placeholder: "Send",
        wrapperClass: "button no-hover",
        // className: " editable-input",
        nonEditable: (
          <button className="button" type="submit" disabled={isSubmitting}>
            {isSubmitting ? "..." : "Send"}
          </button>
        ),
      })}

      {/* {isSubmitting && <div>Submitting...</div>} */}
      {/* {isSubmitted && <div>Submitted.</div>} */}
    </form>
  );
};

export default PromptForm;
