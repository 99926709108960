import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../firebase";

import { getFirestore, doc, setDoc, onSnapshot } from "firebase/firestore";
import { useSessionContext } from "./AdminSessionContext";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBroadcastTower,
  faPalette,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";

const EventName = (props) => {
  const { onSuccess } = props;
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(true);
  const { sessionId } = useSessionContext();
  const navigate = useNavigate();

  useEffect(() => {
    const docRef = doc(db, "sessions", sessionId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        Object.keys(data).forEach((key) => setValue(key, data[key]));
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [sessionId, setValue]);

  const onSubmit = async (data) => {
    const docRef = doc(db, "sessions", sessionId);
    await setDoc(docRef, data, { merge: true });

    navigate(`/${sessionId}/admin/start/types`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="content">
        <h1>Let's get started!</h1>
        <label htmlFor="name">
          Name your event
          <input id="name" {...register("name")} placeholder="Name" />
        </label>

        <button
          className="button"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </button>
        <div className="">
          <Link className="button tile" to={`/${sessionId}/admin/context`}>
            Provide event details
          </Link>
          <Link className="button tile" to={`/${sessionId}/admin/settings`}>
            Choose a theme
          </Link>
          <Link className="button tile" to={`/${sessionId}/admin/live`}>
            Add cards
          </Link>
        </div>
      </div>
    </form>
  );
};

export default EventName;
