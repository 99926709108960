import { useState } from "react";
import Card from "./Card";
import classNames from "classnames";
import { useGoogleFonts, GoogleFontsStatus } from "@flyyer/use-googlefonts";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, EffectCreative, EffectCards } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
const cardContent = [
  {
    id: "prompt",
    cardType: "prompt",
    duration: 30,
    headline: "What have you been working on this week?",
  },
  {
    id: "poll",
    cardType: "poll",
    duration: 10,
    headline: "What's your favorite color?",
    text: "Choose one and only one.",
    options: [
      { text: "Blue", hashtag: "#option1" },
      { text: "Green", hashtag: "#option2" },
      { text: "Red", hashtag: "#option3" },
      { text: "Orange", hashtag: "#option4" },
    ],
  },
  {
    id: "trivia",
    cardType: "trivia",
    duration: 10,
    headline: "Quick Trivia",
    text: "Who commissioned the Hoover Dam?",
    options: [
      { text: "Option 1", hashtag: "#option1" },
      { text: "Option 2", hashtag: "#option2" },
      { text: "Option 3", hashtag: "#option3" },
    ],
  },
  {
    id: "signup",
    cardType: "signup",
    headline: "Are these ideas intriguing to you?",
    text: "Subscribe to our newsletter.",
    dismissable: true,
  },
  {
    id: "wyr",
    cardType: "wyr",
    headline: "Would you rather?",
    options: [
      { text: "Have rain on your wedding day?", hashtag: "#option1" },
      {
        text: "Have 10,000 spoons when all you need is a knife?",
        hashtag: "#option2",
      },
    ],
  },
];
const themes = [
  "theme-default",
  "theme-dark",
  "theme-line",
  "theme-colorful",
  "theme-slido",
  "theme-pixel",
  "theme-celtics",
  "theme-loud",
  "theme-fashion",
];

const viewModes = ["grid", "list", "stack", "swipe", "shuffle", "carousel"];

const CardPlayground = (props) => {
  const [theme, setTheme] = useState("theme-default");
  const [viewMode, setViewMode] = useState("grid");

  const font = useGoogleFonts([
    { family: "Borel", styles: [400] },
    { family: "Libre Baskerville", styles: [400, 600] },
    { family: "Abril Fatface", styles: [400] },
    { family: "Victor Mono", styles: [444] },
    { family: "Inter", styles: ["100..900"] },
    {
      family: "Cabin", // Family Name
      styles: [
        "600..700", // Range, if family supports it.
        "100..200italic", // Range with italic
        "300italic", // Weight with italic
        "regular", // Shortcut to 400
        "italic", // Shortcut to 400 Italic
        "500", // Regular with weight
        444, // Regular weight for variable font
      ],
    },
    {
      family: "Roboto", // Family Name - Roboto doesn't support ranges
      styles: [
        "300italic", // Weight with italic
        "regular", // Shortcut to 400
        "italic", // Shortcut to 400 Italic
        "500",
        100,
      ],
    },
  ]);

  return (
    <div className="playground">
      <div className="themePicker toolbar centered">
        {viewModes.map((v) => (
          <button
            key={v}
            className={classNames("button", { selected: v === viewMode })}
            onClick={() => setViewMode(v)}
          >
            {v}
          </button>
        ))}
        <div style={{ width: "100%" }} />
        {themes.map((t) => (
          <button
            key={t}
            className={classNames("button", { selected: t === theme })}
            onClick={() => setTheme(t)}
          >
            {t}
          </button>
        ))}
      </div>
      {viewMode === "shuffle" && (
        <div className={classNames([theme, "card-shuffle"])}>
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
          >
            {cardContent.map((card) => (
              <SwiperSlide className="card-shuffle-card">
                <Card key={card.id} card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {viewMode === "stack" && (
        <div className={classNames([theme, "card-stack"])}>
          <Swiper
            effect={"creative"}
            pagination={{
              dynamicBullets: true,
            }}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: [0, 0, -400],
                opacity: 0,
              },
              next: {
                translate: ["100%", 0, 0],
              },
            }}
            grabCursor={true}
            modules={[EffectCreative, Pagination]}
          >
            {cardContent.map((card) => (
              <SwiperSlide className="card-stack-card">
                <Card key={card.id} card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      {viewMode === "swipe" && (
        <div className={classNames([theme, "card-swipe"])}>
          <Swiper
            pagination={{
              dynamicBullets: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {cardContent.map((card) => (
              <SwiperSlide className="card-swipe-card">
                <Card key={card.id} card={card} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
      <div className={classNames([theme, "card-gallery"])}>
        {cardContent.map((card) => (
          <div className="card-gallery-card">
            <Card key={card.id} card={card} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardPlayground;
