import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import { useParams } from "react-router-dom";
import EventForm from "./EventForm";
import ThemePicker from "./ThemePicker";

const EventSettings = () => {
  const { sessionId } = useParams();
  const [sessionData, setEventData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const sessionData = doc(db, "sessions", sessionId);
      onSnapshot(sessionData, (doc) => {
        setEventData({ ...doc.data(), id: doc.id });
      });
    };
    fetchData();
  }, [sessionId]);

  return (
    <p>
      Settings<pre>{JSON.stringify(sessionData)}</pre>
      <EventForm />
      <ThemePicker />
    </p>
  );
};
export default EventSettings;
