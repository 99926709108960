import { useState, useEffect, useContext, createContext } from "react";
import { auth, db } from "../firebase";
import {
  GoogleAuthProvider,
  signInAnonymously,
  signInWithPopup,
  signInWithRedirect,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";

const FirebaseAuthContext = createContext();

const provider = new GoogleAuthProvider();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const signIn = async () => {
    // signInWithPopup(auth, provider);
    signInWithRedirect(auth, provider);
  };

  const updateUserRecord = async (userId, userData) => {
    return setDoc(doc(db, "users", userId), userData, { merge: true });
  };

  const updateDisplayName = async (name) => {
    console.log("updateDisplayName", { name, user: auth.currentUser });
    setDisplayName(name);

    if (auth.currentUser) {
      updateUserRecord(auth.currentUser.uid, {
        displayName: name,
      });
      await updateProfile(auth.currentUser, {
        displayName: name,
      })
        .then(() => {
          // Update successful
          // ...
          console.log("update successful?");
        })
        .catch((error) => {
          // An error occurred
          // ...
        });
    } else {
      signInViewer();
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log("user state changed", { user });
      setUser(user);
      if (!displayName && user?.displayName) {
        setDisplayName(user?.displayName);
      }
      if (displayName && user && !user.displayName) {
        updateDisplayName(displayName);
      }
    });
    return unsubscribe;
  }, [setUser, isLoading, displayName]);

  const value = { user, displayName, updateDisplayName, signIn };

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export const signInViewer = () => {
  signInAnonymously(auth)
    .then(() => {
      // Signed in..
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ...
    });
};

export const useFirebaseAuth = () => {
  const context = useContext(FirebaseAuthContext);
  if (context === undefined) {
    throw new Error(
      "useFirebaseAuth must be used within a FirebaseAuthProvider"
    );
  }
  return { ...context, signInViewer };
};

export default AuthProvider;
