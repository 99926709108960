import {
  addDoc,
  collection,
  collectionGroup,
  deleteDoc,
  doc,
  getDocs,
  increment,
  onSnapshot,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { auth, db } from "../firebase";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { compareArrays } from "../util/compareArrays";

export const ViewerSessionContext = createContext();

export const useViewerSessionContext = () => useContext(ViewerSessionContext);

const ViewerSessionProvider = ({ children }) => {
  const user = auth.currentUser;
  const { sessionId } = useParams();
  const [sessionData, setEventData] = useState({});
  const [cards, setCards] = useState([]);
  const [sessionCards, setSessionCards] = useState([]);
  const [interactions, setInteractions] = useState([]);
  const [systemCards, setSystemCards] = useState([
    // { cardType: "confirmation", headline: "Thank you!" },
  ]);

  const addSystemCard = (card, userOptions = {}) => {
    const defaultOptions = { timer: null, dismissable: false };
    const options = { ...defaultOptions, ...userOptions };
    const id = uuidv4();
    const additional = {
      id,
      endBy: options.timer ? Date.now() + options.timer : null,
      dismissable: options.dismissable,
      priority: 0,
    };
    setSystemCards([...systemCards, { ...card, ...additional }]);
    refreshCardList();
  };

  useEffect(() => {
    return onSnapshot(doc(db, "sessions", sessionId), (doc) => {
      console.log({ doc, data: doc.data() });
      if (doc.exists) {
        setEventData({ ...doc.data(), id: doc.id });
      } else {
        console.log("No doc");
      }
    });
  }, [sessionId]);

  useEffect(() => {
    if (sessionId) {
      const cardsCollection = query(
        collection(db, "sessions", sessionId, "cards"),
        where("approved", "==", true),
        where("active", "==", true)
      );

      const unsubscribe = onSnapshot(cardsCollection, (snapshot) => {
        const cardList = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log({ cardList });
        setSessionCards(cardList);
      });

      // Cleanup function to detach the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [sessionId]);

  useEffect(() => {
    if (sessionId && user?.uid) {
      const interactionsCollection = query(
        collectionGroup(db, "interactions"),
        where("userId", "==", user.uid),
        where("sessionId", "==", sessionId)
      );

      const unsubscribe = onSnapshot(interactionsCollection, (snapshot) => {
        const interactionList = snapshot.docs.map((doc) => doc.data());
        console.log({ interactionList });
        setInteractions(interactionList);
      });

      // Cleanup function to detach the listener when the component unmounts
      return () => unsubscribe();
    }
  }, [sessionId, user]);

  const refreshCardList = useCallback(() => {
    const updatedCards = [...sessionCards, ...systemCards].filter((card) => {
      if (card.endBy) {
        return card.endBy > Date.now();
      }

      if (
        card.dismissable &&
        interactions.find(
          (interaction) =>
            interaction.cardId === card.id &&
            interaction.interactionType === "dismiss"
        )
      ) {
        return false;
      }

      if (
        card.cardType === "prompt" &&
        interactions.find(
          (interaction) =>
            interaction.cardId === card.id &&
            interaction.interactionType === "prompt" &&
            card.settings?.afterUserInteraction === "dismiss"
        )
      ) {
        return false;
      }

      if (
        card.cardType === "poll" &&
        interactions.find(
          (interaction) =>
            interaction.cardId === card.id &&
            interaction.interactionType === "poll" &&
            card.settings?.afterUserInteraction === "dismiss"
        )
      ) {
        return false;
      }

      return true;
    });
    if (
      !compareArrays(
        updatedCards.map((card) => card.id),
        cards.map((card) => card.id)
      )
    ) {
      setCards(updatedCards.sort((a, b) => b.priority - a.priority));
    }
  }, [sessionCards, systemCards, cards, interactions]);

  useEffect(() => {
    const interval = setInterval(refreshCardList, 1000);
    return () => clearInterval(interval);
  }, [refreshCardList]);

  //   const cards = [...sessionCards, ...systemCards];

  const sendInteraction = async (sessionId, cardId, interactionData) => {
    if (!auth.currentUser) {
      throw console.error("no current user!");
    }
    const payload = {
      ...interactionData,
      sessionId,
      cardId,
      userId: auth.currentUser.uid,
      displayName: auth.currentUser.displayName,
      createdAt: new Date(),
    };
    console.log("sendInteraction", {
      sessionId,
      cardId,
      interactionData,
      payload,
    });
    addDoc(
      collection(db, "sessions", sessionId, "cards", cardId, "interactions"),
      {
        ...interactionData,
        sessionId,
        cardId,
        userId: auth.currentUser.uid,
        displayName: auth.currentUser.displayName,
        createdAt: new Date(),
      }
    );
    setDoc(
      doc(db, "sessions", sessionId, "cards", cardId),
      {
        totalInteractions: increment(1),
      },
      { merge: true }
    );
  };

  return (
    <ViewerSessionContext.Provider
      value={{
        cards,
        interactions,
        systemCards,
        sessionCards,
        sessionData,
        sessionId,
        addSystemCard,
        refreshCardList,
        sendInteraction,
      }}
    >
      {children}
    </ViewerSessionContext.Provider>
  );
};

export default ViewerSessionProvider;
