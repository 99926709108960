import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import classNames from "classnames";

const ButtonSetInput = ({ name, control, options }) => {
  const { field } = useController({
    name,
    control,
  });

  return (
    <div className="button-set">
      {options.map((option) => (
        <button
          key={option.value}
          type="button"
          onClick={() => field.onChange(option.value)}
          className={classNames(
            "button",
            "secondary",
            "large",
            field.value === option.value ? "active" : ""
          )}
        >
          {option.label}
        </button>
      ))}
    </div>
  );
};

ButtonSetInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default ButtonSetInput;
