import React, { forwardRef } from "react";
import Card from "./Card";
import CardLoader from "./CardLoader";

const DragOverlayItem = forwardRef(({ id, ...props }, ref) => {
  return (
    <div {...props} ref={ref}>
      <CardLoader cardId={id} />
    </div>
  );
});

export default DragOverlayItem;
