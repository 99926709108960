import { useParams } from "react-router-dom";

import Card from "../Card";
import classNames from "classnames";
import "./Viewer.css";
import { useFirebaseAuth } from "../../contexts/AuthProvider";
import ViewerEntryForm from "./ViewerEntryForm";
import ViewerLoggedIn from "./ViewerLoggedIn";
import ViewerEmptyState from "./ViewerEmptyState";
import { useViewerSessionContext } from "../../contexts/ViewerSessionContext";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import useTrackSession from "../../contexts/useTrackSession";
import { faBars, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Viewer = () => {
  const { sessionId } = useParams();
  useTrackSession();

  const { cards, interactions, systemCards, sessionData } =
    useViewerSessionContext();

  const { user } = useFirebaseAuth();

  console.log({ user, cards, interactions });
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div
      className={classNames([
        sessionData?.theme ? sessionData.theme : "theme-default",
      ])}
    >
      <div className={classNames(["viewer"])}>
        <motion.div className={classNames(["card-list"])}>
          <AnimatePresence mode="popLayout">
            {cards &&
              cards.map((card, index) => (
                <motion.div
                  className="viewer-card-wrapper"
                  layout="position"
                  key={card.id}
                  // style={{ position: "relative" }}
                  initial={{ y: 50, opacity: 0, zIndex: 10 }}
                  animate={{ y: 0, opacity: 1, zIndex: 10 }}
                  exit={{ y: -150, opacity: 0, scale: 0.9, zIndex: 5 }}
                  transition={{ duration: 1, ease: "easeInOut" }}
                >
                  <Card key={index} card={{ ...card, sessionId }} />
                </motion.div>
              ))}

            <ViewerEmptyState />
          </AnimatePresence>
        </motion.div>
        {!user && <ViewerEntryForm />}
        <header>
          {/* <div className="session-name">{sessionData?.name}</div> */}
          {showMenu && <ViewerLoggedIn />}
          <button onClick={() => setShowMenu(!showMenu)}>
            <FontAwesomeIcon icon={showMenu ? faXmark : faBars} />
          </button>
        </header>
      </div>
    </div>
  );
};

export default Viewer;
