import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import CardDeckPreview from "./CardDeckPreview";

const CardDecks = () => {
  const [decks, setDecks] = useState([]);
  useEffect(() => {
    getDocs(collection(db, "cardDecks"))
      .then((snapshot) => {
        const deckList = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setDecks(deckList);
      })
      .catch(console.error);
  }, []);

  return (
    <div>
      <div className="content">
        <h1>Card Decks</h1>
        <p>Get inspiration from our universe of ideas.</p>
      </div>
      <div className="card-deck-previews">
        {decks.map((deck) => {
          return (
            <Link to={`${deck.id}`} key={deck.id}>
              <CardDeckPreview deckId={deck.id} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};
export default CardDecks;
