import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import Card from "./Card";
import { Button } from "@ariakit/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { useSessionContext } from "./AdminSessionContext";
import CardGallery from "./CardGallery";

const CardDeck = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();

  const adminSessionContext = useSessionContext();

  const [deckData, setDeckData] = useState({});
  const [cards, setCards] = useState([]);
  const { sessionId, ...otherParams } = useParams();
  const deckId = props.useParam ? otherParams.deckId : props.deckId;
  const cardsCollection = collection(db, "cardDecks", deckId, "cards");

  const fetchCards = async (prompt) => {
    // console.log("starting fetch cards");
    // console.time("card fetching");
    // const response = await axios.get(
    //   `https://cloudrunnode-xtkazthhcq-uc.a.run.app/prompt?prompt=${prompt}`
    // );
    // console.timeEnd("card fetching");
    // return response.data.cards;
  };

  useEffect(() => {
    return onSnapshot(cardsCollection, async (snapshot) => {
      if (!snapshot.empty) {
        const cardsList = snapshot.docs?.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setCards(cardsList);
      }
    });
  }, [deckId]);

  useEffect(() => {
    getDoc(doc(db, "cardDecks", deckId))
      .then((doc) => {
        console.log({ doc });
        if (doc.exists()) {
          setDeckData({ ...doc.data(), id: doc.id });
        } else {
          console.log("No doc");
        }
      })
      .catch(console.error);
  }, [deckId]);

  const editCard = (card) => {
    console.log("Edit card");
    setSearchParams({ action: "edit", cardId: card.id });
  };

  return (
    <div>
      <div className="row">
        <div>
          <h1>{deckData?.name ?? "Untitled"}</h1>
          <p>{deckData?.description ?? ""}</p>
        </div>
        <div>
          <button className="button icon success">
            <FontAwesomeIcon icon={faPlus} /> Add all
          </button>
        </div>
      </div>

      {/* <button
        className="button"
        onClick={async () => {
          await fetchCards(deckData.description).then(async (cards) => {
            console.log(cards);
            if (cards) {
              for (const card of cards) {
                adminSessionContext &&
                  (await adminSessionContext.createCard({
                    ...card,
                    sourceDeckId: deckId,
                    sourceCardId: card.id,
                    approved: true,
                    rejected: false,
                    active: false,
                  }));
              }
            }
          });
        }}
      >
        Generate cards based on description
      </button> */}
      <div className="card-gallery">
        <CardGallery
          cards={cards.map(({ id }) => id)}
          deckId={deckId}
          buildControls={({ card }) => (
            <div className="button-row">
              <button
                className="button icon success"
                data-tooltip-id="controls"
                data-tooltip-content="Add card to your session"
                onClick={() => {
                  const { id, ...rest } = card;
                  adminSessionContext &&
                    adminSessionContext.createCard({
                      ...rest,
                      sourceDeckId: deckId,
                      sourceCardId: id,
                      approved: true,
                      rejected: false,
                      active: false,
                    });
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> Add
              </button>
            </div>
          )}
        />
      </div>
    </div>
  );
};
export default CardDeck;
