// import CardGeneratorLoop from "./CardGeneratorLoop";
import Cards from "./AdminCards";
import EventForm from "./EventForm";
import SpeechRecognitionComponent from "./SpeechRecognitionComponent";
import { useSessionContext } from "./AdminSessionContext"; // import the new context
import CardEditorPortal from "./CardEditorPortal";
import classNames from "classnames";
import {
  Link,
  NavLink,
  Outlet,
  generatePath,
  useSearchParams,
} from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";
import { Portal } from "@ariakit/react";
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import Card from "./Card";
import Timeline from "./Timeline";
import {
  DndContext,
  DragOverlay,
  useSensors,
  useSensor,
  closestCenter,
  TouchSensor,
} from "@dnd-kit/core";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import CardLoader from "./CardLoader";
import Draggable from "./Draggable";
import DragOverlayItem from "./DragOverlayItem";
import { compareArrays } from "../util/compareArrays";
import { KeyboardSensor, MouseSensor } from "../util/dnd-kit";
import Output from "./viewer/Output";
import Responses from "./Responses";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBooks,
  faCog,
  faLightbulb,
  faLightbulbCfl,
  faMobile,
  faMobileAlt,
  faPhone,
  faPhoneAlt,
  faProjector,
  faRectangleList,
  faRectangleVerticalHistory,
  faTowerBroadcast,
} from "@fortawesome/pro-solid-svg-icons";

const Wrapper = () => {
  const [showLiveColumn, setShowLiveColumn] = useState(true);
  const [showLargeQR, setShowLargeQR] = useState(false);
  const [dragCardId, setDragCardId] = useState(null);
  const { sessionId, createNewEvent, sessionData, updateSession } =
    useSessionContext(); // use the context here
  let [searchParams, setSearchParams] = useSearchParams();
  console.log({ sessionData });
  const [localCardOrder, setLocalCardOrder] = useState(
    sessionData?.cardOrder || null
  );
  const [localActiveCards, setLocalActiveCards] = useState(
    sessionData?.activeCards || null
  );

  const sensors = useSensors(
    useSensor(TouchSensor),
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const dndFunctions = {
    onDragStart: (event) => {
      console.log("onDragStart", event);
      setDragCardId(event.active?.id);
    },
    onDragEnd: (event) => {
      setDragCardId(null);
      const { active, over } = event;
      console.log("onDragEnd", { event, active, over });

      if (active?.id !== over?.id) {
        if (
          active.data.current.sortable.containerId ===
            over.data.current.sortable.containerId &&
          over.data.current.sortable.containerId.match(/active|queue/)
        ) {
          console.log(
            "making a move!",
            arrayMove(
              localCardOrder,
              localCardOrder.indexOf(active?.id),
              localCardOrder.indexOf(over?.id)
            )
          );
          let updatedActive = localActiveCards;
          let updatedCardOrder = localCardOrder;
          if (over.data.current.sortable.containerId === "active") {
            updatedActive = [];
          }

          updateSession({
            cardOrder: arrayMove(
              localCardOrder,
              localCardOrder.indexOf(active?.id),
              localCardOrder.indexOf(over?.id)
            ),
          });
          setLocalCardOrder((localCardOrder) => {
            const oldIndex = localCardOrder.indexOf(active?.id);
            const newIndex = localCardOrder.indexOf(over?.id);
            return arrayMove(localCardOrder, oldIndex, newIndex);
          });
        } else {
          console.log("new spot!");
        }
      }
    },
    // onDragMove: (event) => {
    //   console.log("onDragMove", event);
    //   setDragCardId(event.active.id);
    // },
  };

  useEffect(() => {
    if (
      sessionData?.cardOrder &&
      (!localCardOrder ||
        !compareArrays(localCardOrder, sessionData?.cardOrder))
    ) {
      setLocalCardOrder(sessionData?.cardOrder || []);
    }
    if (
      sessionData?.activeCards &&
      (!localActiveCards ||
        !compareArrays(localActiveCards, sessionData?.activeCards))
    ) {
      setLocalActiveCards(sessionData?.activeCards || []);
    }
  }, [localCardOrder, sessionData, localActiveCards]);

  console.log({ localCardOrder, sessionData });

  return (
    <div>
      <DragOverlay>
        {dragCardId ? (
          <DragOverlayItem id={dragCardId}>
            <CardLoader cardId={dragCardId} className="drag-overlay" />
          </DragOverlayItem>
        ) : (
          <div className="card-wrapper rejected">
            <Card
              card={{
                headline: "No active cards",
              }}
            />
          </div>
        )}
      </DragOverlay>
      <CardEditorPortal />
      {showLargeQR &&
        createPortal(
          <div
            className="portal-backdrop backdrop"
            onClick={() => {
              setShowLargeQR(false);
            }}
          >
            <div
              className="portal"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <QRCodeSVG value={`https://side.moe/${sessionId}`} size="500" />
            </div>
          </div>,
          document.body
        )}
      <DndContext
        {...dndFunctions}
        sensors={sensors}
        collisionDetection={closestCenter}
      >
        <div className={classNames("admin-wrapper")}>
          {sessionId && (
            <>
              <div className="toolbar centered">
                <div className="toolbar-start session-name">
                  {sessionData.name}
                </div>
                <NavLink className="tab" to={`/${sessionId}/admin/live`}>
                  <FontAwesomeIcon icon={faTowerBroadcast} /> <span>Host</span>
                </NavLink>
                <NavLink className="tab" to={`/${sessionId}/admin`} end>
                  <FontAwesomeIcon icon={faRectangleVerticalHistory} />
                  <span>Cards</span>
                </NavLink>
                <NavLink className="tab" to={`/${sessionId}/admin/suggestions`}>
                  <FontAwesomeIcon icon={faLightbulb} />{" "}
                  <span>Suggestions</span>
                </NavLink>
                <NavLink className="tab" to={`/${sessionId}/admin/library`}>
                  <FontAwesomeIcon icon={faBooks} /> <span>Library</span>
                </NavLink>
                {/* <NavLink className="tab" to={`/${sessionId}/admin/decks`}>
                  Example Decks
                </NavLink> */}
                <NavLink className="tab" to={`/${sessionId}/admin/settings`}>
                  <FontAwesomeIcon icon={faCog} /> <span>Settings</span>
                </NavLink>
                <NavLink
                  className="button secondary"
                  to={`/${sessionId}/output`}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faProjector} fixedWidth />
                </NavLink>
                <NavLink
                  className="button secondary"
                  to={`/${sessionId}`}
                  end
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faMobileAlt} fixedWidth />
                </NavLink>
                {/* <QRCodeSVG
                  value={`https://side.moe/${sessionId}`}
                  width={40}
                  onClick={() => setShowLargeQR(true)}
                /> */}
                <div className="button-row">
                  <button
                    onClick={() => {
                      setSearchParams({ action: "new" });
                    }}
                    className="button"
                  >
                    New Card
                  </button>
                </div>
              </div>
              <div
                className={classNames(
                  "theme-default",
                  "center-column"
                  // sessionData?.theme ? sessionData.theme : "theme-default"
                )}
              >
                <div className="outlet">
                  <Outlet />
                </div>
                <Timeline queue={localCardOrder} />
              </div>
              {/* {showLiveColumn && (
                <div className="inset">
                  <div className="toolbar">
                    <NavLink
                      className="button secondary"
                      to={`/${sessionId}/output`}
                      target="_blank"
                    >
                      Output Page
                    </NavLink>
                    <button
                      className="button"
                      onClick={() => setShowLiveColumn(false)}
                    >
                      Hide Live Panel
                    </button>
                  </div>
                  <div
                    className={classNames(
                      "content",
                      "theme-default"
                      // sessionData?.theme ? sessionData.theme : "theme-default"
                    )}
                  >
                    <div className="preview-output-wrapper">
                      <div className="preview-output">
                        <Output showHeader={false} />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="toolbar">
                      <NavLink
                        className="button secondary"
                        to={`/${sessionId}`}
                        target="_blank"
                      >
                        Participant Page
                      </NavLink>
                      <QRCodeSVG
                        value={`https://side.moe/${sessionId}`}
                        width={40}
                        onClick={() => setShowLargeQR(true)}
                      />
                    </div>
                    <div
                      className={classNames(
                        "viewer-preview",
                        sessionData?.theme ? sessionData.theme : "theme-default"
                      )}
                    >
                      {localActiveCards?.map((cardId) => (
                        <CardLoader cardId={cardId} />
                      ))}
                    </div>
                  </div>
                </div>
              )} */}
            </>
          )}
        </div>
      </DndContext>
    </div>
  );
};

export default Wrapper;
