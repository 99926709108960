// SessionContext.js
import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  collection,
  addDoc,
  doc,
  getDocs,
  setDoc,
  onSnapshot,
  updateDoc,
  deleteDoc,
  deleteField,
  arrayUnion,
  Timestamp,
  arrayRemove,
  writeBatch,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { cardTypes } from "./CardTypePicker";
import axios from "axios";

const SessionContext = createContext();

export const useSessionContext = () => useContext(SessionContext);
function generateUniqueID() {
  // Exclude easily confused characters like 1, I, 0, O, etc.
  const chars = "23456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghjklmnpqrstuvwxyz";
  let id = "";

  for (let i = 0; i < 5; i++) {
    id += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  return id.toLocaleLowerCase();
}

export const SessionProvider = ({ children }) => {
  const userId = auth.currentUser?.uid;
  const { sessionId } = useParams();
  const navigate = useNavigate();
  const [cards, setCards] = useState([]);
  const [activeCards, setActiveCards] = useState([]);
  const [sessionData, setEventData] = useState({});

  const createNewEvent = useCallback(async () => {
    const newEventId = generateUniqueID();
    const newEventRef = await setDoc(doc(db, "sessions", newEventId), {
      name: "Untitled",
      sessionType: "",
      theme: "theme-default",
      context: "",
      samples: "",
      history: "",
      transcript: "",
      createdBy: auth.currentUser?.uid,
    });
    navigate(`/${newEventId}/admin/start/form`);
    return newEventRef;
  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      const cardsCollection = collection(
        doc(db, "sessions", sessionId),
        "cards"
      );
      onSnapshot(cardsCollection, (cardSnapshot) => {
        const cardList = cardSnapshot.docs
          ? cardSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
          : [];
        console.log(cardList);
        setCards(cardList);
        setActiveCards(cardList.filter((card) => card.approved && card.active));
      });
    };
    if (sessionId) fetchData();
  }, [sessionId]);

  useEffect(() => {
    const fetchData = async () => {
      const sessionData = doc(db, "sessions", sessionId);
      onSnapshot(sessionData, (doc) => {
        setEventData({ ...doc.data(), id: doc.id });
      });
    };
    if (sessionId) fetchData();
  }, [sessionId]);

  const updateCard = async (cardId, cardData) => {
    console.log("updateCard", { sessionId, cardId, cardData });
    return await setDoc(
      doc(db, "sessions", sessionId, "cards", cardId),
      { updatedAt: serverTimestamp(), ...cardData },
      {
        merge: true,
      }
    );
  };

  const createCard = async (cardData) => {
    const defaultData = cardTypes.find(
      (cardType) => cardType.cardType === cardData.cardType
    )?.defaultData;
    console.log({ cardTypes, cardData, defaultData });
    const newCardRef = await addDoc(
      collection(db, "sessions", sessionId, "cards"),
      {
        sessionId,
        ...defaultData,
        ...cardData,
        createdBy: userId,
        createdAt: new Date(),
        updatedAt: new Date(),
      }
    );
    updateSession({
      cardOrder: arrayUnion(newCardRef.id),
    });
    return newCardRef;
  };

  const updateSession = async (sessionData) => {
    return await setDoc(doc(db, "sessions", sessionId), sessionData, {
      merge: true,
    });
  };

  const clearAllCardInteractions = async (sessionId, cardId) => {
    const interactionsCollection = collection(
      db,
      "sessions",
      sessionId,
      "cards",
      cardId,
      "interactions"
    );
    const interactionsSnapshot = await getDocs(interactionsCollection);
    console.log({ interactionsSnapshot });
    interactionsSnapshot.forEach((doc) => {
      deleteDoc(doc.ref);
    });
    const cardRef = doc(db, "sessions", sessionId, "cards", cardId);
    setDoc(cardRef, { totalInteractions: 0 }, { merge: true });
  };

  const approveInteraction = async (sessionId, cardId, interactionId) => {
    console.log("approveInteraction", { sessionId, cardId, interactionId });
    const interactionRef = doc(
      db,
      "sessions",
      sessionId,
      "cards",
      cardId,
      "interactions",
      interactionId
    );
    return updateDoc(interactionRef, {
      approved: true,
      rejected: false,
      updatedAt: new Date(),
      updatedBy: userId,
    });
  };

  const setInteractionActive = async (sessionId, cardId, interactionId) => {
    console.log("setInteractionActive", { sessionId, cardId, interactionId });
    const interactionRef = doc(
      db,
      "sessions",
      sessionId,
      "cards",
      cardId,
      "interactions",
      interactionId
    );
    const batch = writeBatch(db);
    batch.set(
      interactionRef,
      {
        active: true,
        updatedAt: new Date(),
        updatedBy: userId,
      },
      { merge: true }
    );
    batch.set(
      doc(db, "sessions", sessionId, "cards", cardId),
      { activeInteractions: [interactionId] },
      { merge: true }
    );
    return batch.commit();
  };

  const setInteractionInactive = async (sessionId, cardId, interactionId) => {
    console.log("setInteractionInactive", { sessionId, cardId, interactionId });
    const interactionRef = doc(
      db,
      "sessions",
      sessionId,
      "cards",
      cardId,
      "interactions",
      interactionId
    );
    const batch = writeBatch(db);
    batch.set(
      interactionRef,
      {
        active: false,
        updatedAt: new Date(),
        updatedBy: userId,
      },
      { merge: true }
    );
    batch.set(
      doc(db, "sessions", sessionId, "cards", cardId),
      { activeInteractions: arrayRemove(interactionId) },
      { merge: true }
    );
    return batch.commit();
  };

  const rejectInteraction = async (sessionId, cardId, interactionId) => {
    const interactionRef = doc(
      db,
      "sessions",
      sessionId,
      "cards",
      cardId,
      "interactions",
      interactionId
    );
    return updateDoc(interactionRef, {
      approved: false,
      rejected: true,
      updatedAt: new Date(),
      updatedBy: userId,
    });
  };

  let [searchParams, setSearchParams] = useSearchParams();

  const approveCard = (card) => {
    updateCard(card.id, {
      ...card,
      approved: true,
      rejected: deleteField(),
      updatedBy: userId,
    });
    updateSession({
      cardOrder: arrayUnion(card.id),
    });
  };

  const sendNextCard = () => {
    console.log("sendNextCard", activeCards, sessionData.cardOrder);
    if (activeCards.length > 0) {
      const currentCardIndex = sessionData.cardOrder.findIndex(
        (id) => id === activeCards[0].id
      );
      const nextCardId = sessionData.cardOrder[currentCardIndex + 1];

      if (nextCardId) {
        sendCard(nextCardId);
      } else {
        sendCard(null);
      }
    } else {
      sendCard(sessionData.cardOrder[0]);
    }
  };

  const deactivateCard = (cardId) => {
    updateCard(cardId, { active: false });
    updateSession({
      activeCards: arrayRemove(cardId),
    });
  };

  const rejectCard = (card) => {
    updateCard(card.id, {
      ...card,
      rejected: true,
      approved: deleteField(),
      active: false,
      updatedBy: userId,
    });
    updateSession({
      activeCards: arrayRemove(card.id),
      cardOrder: arrayRemove(card.id),
    });
  };

  const sendCard = (cardId) => {
    const now = Timestamp.now();
    const startAt = now;

    if (activeCards.length > 0) {
      deactivateCard(activeCards[0].id);
    }
    if (cardId) {
      updateCard(cardId, {
        approved: true,
        rejected: deleteField(),
        active: true,
        startAt,
        updatedBy: userId,
      });
      updateSession({
        activeCards: [cardId],
      });
    } else {
      updateSession({ activeCards: [] });
    }
  };

  const launchCardEditor = (card) => {
    console.log("Edit card");
    setSearchParams({ action: "edit", cardId: card.id });
  };

  const fixMissingCardOrder = () => {
    const cardOrder = cards
      .filter((card) => card.approved)
      .map((card) => card.id);

    console.log("fixMissingCardOrder", { cardOrder, cards });

    updateSession({ cardOrder });
  };

  const initiateSuggestion = async (suggestionData) => {
    console.log("initiateSuggestion", { suggestionData });
    const suggestionRef = await addDoc(collection(db, "suggestions"), {
      userPrompt: suggestionData.userPrompt,
      prompt: suggestionData.prompt,
      model: suggestionData.model,
      sessionId,
      userId,
      loading: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      completedAt: null,
    });

    console.log("axios", { suggestionId: suggestionRef.id });

    const response = await axios
      .post(`https://cloudrun-xtkazthhcq-uc.a.run.app/generateSuggestions`, {
        suggestionId: suggestionRef.id,
      })
      .catch((err) => console.error);

    console.log({ data: response.data });
  };

  const fetchMoreSuggestions = async (suggestionId) => {
    return await axios
      .post(`https://cloudrun-xtkazthhcq-uc.a.run.app/generateSuggestions`, {
        suggestionId,
      })
      .catch((err) => console.error);
  };

  const sendGPTPrompt = async ({
    prompt = "",
    systemPrompt = "",
    model = "gpt-3.5-turbo-0613",
  }) => {
    return await axios
      .post(`https://cloudrun-xtkazthhcq-uc.a.run.app/prompt`, {
        prompt,
        systemPrompt,
        model,
      })
      .catch((err) => console.error);
  };
  const getCard = (cardId) => {
    return cards.find((card) => card.id === cardId);
  };

  const createNewCardFromApprovedInteractions = async (cardId) => {
    const interactions = await getDocs(
      query(
        collection(db, "sessions", sessionId, "cards", cardId, "interactions"),
        where("approved", "==", true)
      )
    );
    const newCard = {
      cardType: "poll",
      headline: "Vote on results",
      options: interactions.docs.map((interaction) => ({
        text: interaction.data().response,
      })),
    };
    console.log({ newCard });
    createCard(newCard);
  };

  const slugify = (text) => {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w-]+/g, "") // Remove all non-word chars
      .replace(/--+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  };

  const addContentLibraryDoc = async ({
    name = "Untitled",
    contentType = "text",
    content = "",
  }) => {
    const newDoc = addDoc(collection(db, "contentLibrary"));
    return await updateDoc(newDoc, {
      name,
      contentType,
      content,
      slug: slugify(name),
      createdBy: userId,
      createdAt: new Date(),
      updatedAt: new Date(),
    });
  };

  const value = {
    sessionId,
    createNewEvent,
    cards,
    activeCards,
    updateCard,
    getCard,
    sessionData,
    updateSession,
    createCard,
    clearAllCardInteractions,
    approveCard,
    launchCardEditor,
    sendCard,
    rejectCard,
    deactivateCard,
    approveInteraction,
    rejectInteraction,
    setInteractionActive,
    setInteractionInactive,
    sendNextCard,
    fixMissingCardOrder,
    initiateSuggestion,
    fetchMoreSuggestions,
    sendGPTPrompt,
    createNewCardFromApprovedInteractions,
    addContentLibraryDoc,
  };

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};
