import {
  collection,
  collectionGroup,
  doc,
  increment,
  onSnapshot,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useSessionContext } from "./AdminSessionContext";
import classNames from "classnames";
import { compareArrayWithUpdatedAt } from "../util/compareArrays";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faStar,
  faStarHalfStroke,
  faTimes,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import AdminParticipation from "./AdminParticipation";

const salesResponses = [
  "It was very informative!",
  "I felt some sections were rushed.",
  "Could we have more hands-on activities?",
  "Loved the real-world examples given.",
  "Can we get more in-depth on closing techniques?",
  "Some modules felt redundant.",
  "The pacing was perfect for me.",
  "Wish there were more breaks.",
  "The trainer was very engaging.",
  "It would help if we had more role-playing sessions.",
  "I'm still unsure about the new software.",
  "I appreciated the workbook and materials provided.",
  "More feedback during practice would be great.",
  "The training venue was a bit noisy.",
  "Loved the group activities and discussions.",
  "The case studies were very helpful.",
  "Could we have a follow-up session?",
  "I would prefer more real-time sales demos.",
  "The content was too basic for me.",
  "How about some advanced training sessions?",
  "The slides were a bit cluttered.",
  "I'd like more focus on negotiation techniques.",
  "The Q&A session was very beneficial.",
  "The online platform had some glitches.",
  "Some external expert sessions would be nice.",
  "The session on objection handling was great.",
  "Would be better with more breaks in between.",
  "More tools and resources would be helpful.",
  "Some videos were outdated.",
  "The trainer was very knowledgeable.",
  "More focus on digital sales methods, please.",
  "It felt too long; could be more concise.",
  "The practical exercises were the best part.",
  "I was lost in the module on analytics.",
  "I'd appreciate a detailed handout to refer back to.",
  "It was interactive and kept me engaged.",
  "Some parts were too theoretical.",
  "The post-training resources were great.",
  "More emphasis on customer psychology would be useful.",
  "The session on cold calling was a bit intimidating.",
  "Wish there was a feedback session after each module.",
  "I'd like more tips on email outreach.",
  "Can we cover social media selling more?",
  "I'd love to hear more success stories.",
  "More group work would be great.",
  "The examples given were very relatable.",
  "It was good but felt a bit outdated.",
  "I would've liked more one-on-one time with the trainer.",
  "More emphasis on modern sales tools, please.",
  "Overall, it was a great experience!",
];

const generateSportsResponses = () => {
  const sports = [
    "Football",
    "Football",
    "Football",
    "Football",
    "Football",
    "Basketball",
    "Basketball",
    "Basketball",
    "Basketball",
    "Baseball",
    "Baseball",
    "Baseball",
    "Baseball",
    "Baseball",
    "Baseball",
    "Baseball",
    "Soccer",
    "Hockey",
    "Tennis",
    "Golf",
    "Volleyball",
    "Swimming",
    "Track and Field",
    "Wrestling",
    "Boxing",
    "MMA",
    "MMA",
    "MMA",
    "Cycling",
    "Cycling",
    "Rugby",
    "Cricket",
    "Table Tennis",
    "Badminton",
    "Skiing",
    "Skating",
    "Snowboarding",
    "Surfing",
    "Skateboarding",
    "Skateboarding",
    "Skateboarding",
    "Skateboarding",
    "Gymnastics",
    "Diving",
    "Bowling",
    "Billiards",
  ];

  const responses = [];

  for (let i = 0; i < 100; i++) {
    const randomIndex = Math.floor(Math.random() * sports.length);
    responses.push(`${sports[randomIndex]}.`);
  }

  return responses;
};

const Responses = ({ sessionId, cardId }) => {
  const [responses, setResponses] = useState([]);
  const sessionContext = useSessionContext();
  const card = sessionContext.getCard(cardId);

  useEffect(() => {
    const interactionsCollectionRef = cardId
      ? collection(db, "sessions", sessionId, "cards", cardId, "interactions")
      : query(
          collectionGroup(db, "interactions"),
          where("sessionId", "==", sessionId),
          orderBy("createdAt", "desc")
        );
    return onSnapshot(interactionsCollectionRef, (snapshot) => {
      const newResponses = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (!compareArrayWithUpdatedAt(responses, newResponses)) {
        setResponses(newResponses);
      }
    });
  }, [sessionId, cardId]);

  const generateSummary = async () => {
    const systemPrompt = `Please summarize the responses to the prompt ${card.headline}. Please try and define general sentiments, trends, topics and themes. Use clear concise prose, 2-3 bullet points, no more than 30 words total.`;
    const prompt = responses.map((response) => response.response).join("\n");

    console.log({ systemPrompt, prompt });

    const result = await sessionContext.sendGPTPrompt({ systemPrompt, prompt });

    sessionContext.updateCard(cardId, {
      responseSummary: result.data,
    });

    console.log({ result });
  };

  const sendSampleResponses = async (responses) => {
    const batch = writeBatch(db);

    responses.forEach((response) => {
      const interactionRef = doc(
        collection(db, "sessions", sessionId, "cards", cardId, "interactions")
      );

      batch.set(interactionRef, {
        displayName: "Sample User",
        response,
        sessionId,
        cardId,
        userId: "system",
        createdAt: new Date(),
      });
    });

    batch.set(
      doc(db, "sessions", sessionId, "cards", cardId),
      {
        totalInteractions: increment(responses.length),
      },
      { merge: true }
    );
    await batch.commit();
  };

  return (
    <div className="admin-responses">
      <div className="toolbar sticky bg2 padded">
        {/* <button
          className="button"
          onClick={() => {
            sendSampleResponses(generateSportsResponses());
          }}
        >
          Colors
        </button>
        <button
          className="button"
          onClick={() => {
            sendSampleResponses(salesResponses);
          }}
        >
          Sales
        </button> */}
        <button
          className="button"
          disabled={!responses.length}
          onClick={() => {
            generateSummary();
          }}
        >
          Summarize
        </button>
        <button
          className="button"
          disabled={!responses.length}
          onClick={() => {
            sessionContext &&
              sessionContext.createNewCardFromApprovedInteractions(cardId);
          }}
        >
          New Poll
        </button>
        <button
          className="button"
          disabled={!responses.length}
          onClick={() => {
            sessionContext &&
              sessionContext.clearAllCardInteractions(sessionId, cardId);
          }}
        >
          Clear all
        </button>
      </div>
      {/* <div>Show: All Appoved Rejected</div> */}
      {card?.responseSummary && (
        <div className="response-wrapper response-summary">
          <div className="display-name">Summary</div>
          <div className="button-row">
            <button
              className="button"
              onClick={() => {
                sessionContext.updateCard(cardId, { responseSummary: "" });
              }}
            >
              Clear summary
            </button>
          </div>
          <div className="response">{card?.responseSummary}</div>
        </div>
      )}
      <div>
        <AdminParticipation
          cardId={cardId}
          participantIds={responses.map((response) => response.userId)}
        />
      </div>
      {responses && responses.length ? (
        responses.map((interaction, i) => (
          <div
            className={classNames("response-wrapper", {
              active: interaction.active,
              rejected: interaction.rejected,
              approved: interaction.approved,
            })}
            key={interaction.id}
          >
            <div className="displayName">{interaction.displayName}</div>
            <div className="button-row">
              <button
                onClick={() => {
                  sessionContext &&
                  card?.activeInteractions?.includes(interaction.id)
                    ? sessionContext.setInteractionInactive(
                        interaction.sessionId,
                        interaction.cardId,
                        interaction.id
                      )
                    : sessionContext.setInteractionActive(
                        interaction.sessionId,
                        interaction.cardId,
                        interaction.id
                      );
                }}
                className={classNames(
                  "button",
                  card?.activeInteractions?.includes(interaction.id)
                    ? "active"
                    : "inactive"
                )}
              >
                <FontAwesomeIcon icon={faStar} />
              </button>
              <button
                className={classNames("button", "warning")}
                onClick={() => {
                  sessionContext &&
                    sessionContext.rejectInteraction(
                      interaction.sessionId,
                      interaction.cardId,
                      interaction.id
                    );
                }}
                disabled={interaction.rejected}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
              <button
                onClick={() => {
                  console.log({
                    sessionId,
                    cardId,
                    interactionId: interaction.id,
                  });
                  sessionContext &&
                    sessionContext.approveInteraction(
                      interaction.sessionId,
                      interaction.cardId,
                      interaction.id
                    );
                }}
                className={classNames("button", "success")}
                disabled={interaction.approved}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </div>
            <div className="response">{interaction.response}</div>
          </div>
        ))
      ) : (
        <div>No responses yet</div>
      )}
    </div>
  );
};
export default Responses;
