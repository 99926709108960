import { useForm } from "react-hook-form";
import AuthProvider, { useFirebaseAuth } from "../../contexts/AuthProvider";
import { useContext } from "react";

const ViewerEntryForm = () => {
  const { register, handleSubmit } = useForm();
  const { user, updateDisplayName } = useFirebaseAuth();

  const onSubmit = (data) => {
    console.log(data);
    updateDisplayName(data.name);
  };

  return (
    <div
      className="portal-backdrop backdrop"
      //   onClick={() => {
      //     if (searchParams.has("action")) {
      //       searchParams.delete("action");
      //       setSearchParams({});
      //     }
      //   }}
    >
      <div
        className="portal"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <div className="viewer-entry-form ">
          <div className="card">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <label htmlFor="name" className="headline">
                  What's your name?
                </label>
              </div>
              <div className="form-row">
                <input id="name" className="input" {...register("name")} />
              </div>
              <button
                type="submit"
                className="button"
                onClick={handleSubmit(onSubmit)}
              >
                Continue
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewerEntryForm;
