export const compareArrays = (a, b) =>
  a.length === b.length && a.every((element, index) => element === b[index]);

const objectsAreEqual = (obj1, obj2) => {
  return obj1.id === obj2.id && obj1.updatedAt === obj2.updatedAt;
};

export const compareArrayWithUpdatedAt = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (!objectsAreEqual(arr1[i], arr2[i])) return false;
  }
  return true;
};
