import {
  faCheckCircle,
  faPlusCircle,
  faTimesCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useViewerSessionContext } from "../contexts/ViewerSessionContext";
import classNames from "classnames";

const Options = (props) => {
  const { renderEditableText, afterSubmit, card } = props;
  const viewerSessionContext = useViewerSessionContext();
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const onSelect = async (data) => {
    viewerSessionContext?.sendInteraction &&
      (await viewerSessionContext.sendInteraction(card.sessionId, card.id, {
        interactionType: card.cardType,
        ...data,
        card,
      }));
    viewerSessionContext
      ? viewerSessionContext.addSystemCard(
          {
            cardType: "confirmation",
            headline: card.confirmationText || "Thank you!",
          },
          { timer: 5000 }
        )
      : console.log("no viewer session context");
    // reset();
    afterSubmit && afterSubmit(data, card);
  };

  return (
    <div
      className={classNames("options", {
        "over-3-options": props.card.options?.length > 3,
        "over-5-options": props.card.options?.length > 5,
        "over-10-options": props.card.options?.length > 10,
      })}
    >
      {props.card.options?.map((option, i) => (
        <div
          key={(option.id ?? option.text) + i}
          className={`option ${
            option.text === selectedAnswer ? "selected" : ""
          }`}
          onClick={() => {
            setSelectedAnswer(option.text);
            onSelect({ response: option.text });
          }}
        >
          <span className="option-text">{option.text}</span>
          {selectedAnswer && props.card.type === "trivia" ? (
            <>
              {option.correct ? (
                <span className="correct-icon">✔</span>
              ) : (
                <span className="incorrect-icon">👎</span>
              )}
            </>
          ) : (
            <>
              {option.text === selectedAnswer ? (
                <span className="vote-icon">✔</span>
              ) : null}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
export default Options;
