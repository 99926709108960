import React from "react";
import { useDraggable } from "@dnd-kit/core";

function Draggable(props) {
  const Element = props.element || "div";
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <Element ref={setNodeRef} {...listeners} {...attributes} style={style}>
      {props.children}
    </Element>
  );
}

export default Draggable;
