import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../firebase";

import { getFirestore, doc, setDoc, onSnapshot } from "firebase/firestore";
import { useSessionContext } from "./AdminSessionContext";
const themes = [
  "theme-default",
  "theme-dark",
  "theme-line",
  "theme-colorful",
  "theme-slido",
  "theme-pixel",
  "theme-celtics",
  "theme-loud",
  "theme-fashion",
];

const EventForm = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(true);
  const { sessionId, fixMissingCardOrder } = useSessionContext();

  useEffect(() => {
    const docRef = doc(db, "sessions", sessionId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        Object.keys(data).forEach((key) => setValue(key, data[key]));
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [sessionId, setValue]);

  const onSubmit = async (data) => {
    const docRef = doc(db, "sessions", sessionId);
    Object.keys(data).forEach(
      (key) => data[key] === undefined && delete data[key]
    );

    await setDoc(docRef, data, { merge: true });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label htmlFor="name">
        Name
        <input id="name" {...register("name")} placeholder="Name" />
      </label>

      <label htmlFor="theme">
        Theme
        <div>
          <select id="theme" {...register("theme")} placeholder="Theme">
            {themes.map((theme) => (
              <option key={theme} value={theme}>
                {theme}
              </option>
            ))}
          </select>
        </div>
      </label>

      <label htmlFor="eventType">
        Event Type
        <input
          id="eventType"
          {...register("eventType")}
          placeholder="Event Type"
        />
      </label>

      <label htmlFor="prompt">
        Prompt
        <textarea id="prompt" {...register("prompt")} placeholder="Prompt" />
      </label>

      <label htmlFor="context">
        Context
        <textarea id="context" {...register("context")} placeholder="Context" />
      </label>

      <label htmlFor="samples">
        Samples
        <textarea id="samples" {...register("samples")} placeholder="Samples" />
      </label>

      <label htmlFor="history">
        History
        <textarea id="history" {...register("history")} placeholder="History" />
      </label>

      {/* {fixMissingCardOrder && ( */}
      <button type="button" onClick={() => fixMissingCardOrder()}>
        Fix Missing Card Order
      </button>
      {/* )} */}

      <button type="submit">Save</button>
    </form>
  );
};

export default EventForm;
