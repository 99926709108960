import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Card from "./Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faThumbsUp } from "@fortawesome/pro-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { useForm } from "react-hook-form";
import { useSessionContext } from "./AdminSessionContext";
import CountUpTimer from "./CountupTimer";
import { cardTypes } from "./CardTypePicker";

const buildPrompt = (data) => {
  return `Here are descriptions of card types the user is interested in and you can use:
  ${Object.keys(data.cardTypes)
    .filter((key) => data.cardTypes[key])
    .map((typeSlug, i) => {
      return `
      [{i}] ${typeSlug}: ${
        cardTypes.find((cardType) => cardType.cardType === typeSlug)
          ?.gptDescription
      }
    `;
    })}
  Here's the user's request: ${data.userPrompt}`;
};

const Suggestions = () => {
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  const {
    sessionData,
    updateSession,
    createCard,
    initiateSuggestion,
    fetchMoreSuggestions,
  } = useSessionContext();

  const [cards, setCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const { sessionId } = useParams();

  const { register, handleSubmit, reset } = useForm({
    defaultValues: {
      userPrompt: "",
      model: "gpt-3.5-turbo-0613",
      cardTypes: {
        prompt: true,
      },
    },
  });
  const onSubmit = async (data) => {
    setStartTime(new Date());
    setEndTime(null);

    updateSession({
      userPrompt: data.userPrompt,
      cardTypes: data.cardTypes,
    });

    const prompt = buildPrompt(data);

    await initiateSuggestion({
      userPrompt: data.userPrompt,
      prompt,
      model: data.model,
    });

    setEndTime(new Date());
  };

  useEffect(() => {
    onSnapshot(
      query(
        collection(db, "suggestions"),
        where("sessionId", "==", sessionId),
        orderBy("updatedAt", "desc")
      ),
      (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSuggestions(newData);
      }
    );
  }, [sessionId]);

  useEffect(() => {
    reset({ context: sessionData.context, cardTypes: sessionData.cardTypes });
  }, [sessionData, reset]);

  const addAll = async () => {
    console.log("Add all");
    for (const card of cards) {
      const { id, ...rest } = card;
      createCard({
        ...rest,
        approved: true,
        rejected: false,
        active: false,
      });
    }
  };

  return (
    <div className="suggestions-page-wrapper">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field-group">
          <div className="field multirow">
            <label className="label" htmlFor="userPrompt">
              Enter your prompt.
            </label>
            <textarea
              {...register("userPrompt")}
              placeholder="Onboarding for new employees at a startup"
            />
          </div>
          <div className="field">
            <label>Model?</label>
            <select {...register("model")}>
              <option value="gpt-4-0613">GPT-4</option>
              <option value="gpt-3.5-turbo-0613">GPT-3.5 Turbo</option>
            </select>
          </div>
          <div className="field multirow">
            <label>Card Types</label>
            <div className="row wrap">
              {Object.values(cardTypes).map((type) => (
                <label className="button">
                  <input
                    type="checkbox"
                    {...register(`cardTypes.${type.cardType}`)}
                  />
                  {type.name}
                </label>
              ))}
            </div>
          </div>
        </div>
        <button className="button" type="submit" disabled={loading}>
          Generate suggestions
        </button>
        {startTime && <CountUpTimer startTime={startTime} endTime={endTime} />}
      </form>

      <div>
        <div className="suggestions">
          {suggestions &&
            suggestions.length > 0 &&
            suggestions.map((suggestion) => (
              <div className="suggestion-block">
                <div>
                  <div>
                    <h1 className="headline">{suggestion.userPrompt}</h1>
                  </div>
                  {suggestion.loading ? <span>Loading</span> : null}
                  {suggestion.startedAt && (
                    <CountUpTimer
                      startTime={suggestion.startedAt?.toDate()}
                      endTime={suggestion.completedAt?.toDate()}
                    />
                  )}
                  <button
                    onClick={() => {
                      fetchMoreSuggestions(suggestion.id);
                    }}
                    className="button"
                  >
                    More
                  </button>
                </div>
                <div className="cards">
                  {Array.isArray(suggestion.result) &&
                    suggestion.result.map((card) => (
                      <div className="">
                        <Card card={card} outline />
                        <div className="button-row">
                          <Tooltip id="controls" />
                          <button
                            className="button icon secondary"
                            // onClick={() => launchCardEditor(card)}
                            data-tooltip-id="controls"
                            data-tooltip-content="Edit card"
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                          <button
                            className="button icon success"
                            data-tooltip-id="controls"
                            data-tooltip-content="Approve card"
                            onClick={() => {
                              const { id, ...rest } = card;

                              createCard({
                                ...rest,
                                approved: true,
                                rejected: false,
                                active: false,
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faThumbsUp} />
                          </button>
                        </div>
                      </div>
                    ))}
                  {suggestion.result?.cardType && (
                    <Card card={suggestion.result} outline />
                  )}
                </div>
              </div>
            ))}
          {cards && cards.length > 0 && (
            <button className="button icon success" onClick={addAll}>
              <FontAwesomeIcon icon={faThumbsUp} /> Approve all
            </button>
          )}
        </div>
        <div className="card-gallery grid">
          {loading && (
            <div className="card">
              <h1>Thinking really hard...</h1>
              <img
                src={
                  "https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExZ240cWFnOHV0eWpmcTAyN3Fxamlwemo3d2Q2MG1yaWdkdmt1cGZndSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9cw/kPWY1iSFkBxiZEDg3t/giphy.gif"
                }
                alt="loading"
                width="240"
              />
            </div>
          )}
          {cards.length === 0 && !loading && (
            <div className="card rejected">
              <h1>No cards here</h1>
            </div>
          )}
          <div className="card-gallery-cards">
            {cards.map((card) => {
              return (
                <div>
                  <Card card={card} />
                  <div className="button-row">
                    <Tooltip id="controls" />
                    <button
                      className="button icon secondary"
                      // onClick={() => launchCardEditor(card)}
                      data-tooltip-id="controls"
                      data-tooltip-content="Edit card"
                    >
                      <FontAwesomeIcon icon={faPencil} />
                    </button>
                    <button
                      className="button icon success"
                      data-tooltip-id="controls"
                      data-tooltip-content="Approve card"
                      onClick={() => {
                        const { id, ...rest } = card;

                        createCard({
                          ...rest,
                          approved: true,
                          rejected: false,
                          active: false,
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faThumbsUp} />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Suggestions;
