import { useEffect, useState } from "react";
import { useSessionContext } from "./AdminSessionContext";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import classNames from "classnames";
import WordcloudOutput from "./WordcloudOutput";
import { compareArrayWithUpdatedAt } from "../util/compareArrays";
import OutputOptionsGrid from "./OutputOptionsGrid";

const normalize = (str) => str.toLowerCase().replace(/\s/g, "");

const calculatePollResults = (options, responses) => {
  const pollResults = options
    ? options.map((option) => ({
        option,
        count: responses.filter(
          (response) => normalize(response.response) === normalize(option.text)
        ).length,
      }))
    : [];
  const totalVotes = pollResults.reduce((acc, result) => {
    return acc + result.count;
  }, 0);
  pollResults.forEach((result) => {
    result.percentage =
      totalVotes === 0 ? 0 : Math.round((result.count / totalVotes) * 100);
  });

  return pollResults;
};

const TriviaOutput = (props) => {
  const { card } = props;
  const [responses, setResponses] = useState([]);
  const sessionContext = useSessionContext();
  // const interactionsCollectionRef = collection(
  //   db,
  //   "sessions",
  //   props.card.sessionId,
  //   "cards",
  //   props.card.id,
  //   "interactions"
  // );
  const sessionId = props.card.sessionId;
  const cardId = props.card.id;
  const viewMode = props.card.settings?.outputViewMode;

  const interactionsCollectionRef = query(
    collection(db, "sessions", sessionId, "cards", cardId, "interactions"),
    props.card.settings?.outputResultsShown === "approved"
      ? where("approved", "==", true)
      : undefined,
    orderBy("createdAt", "desc")
  );

  useEffect(() => {
    // return onSnapshot(interactionsCollectionRef, (snapshot) => {
    //   setResponses(snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    // });

    return onSnapshot(interactionsCollectionRef, (snapshot) => {
      const newResponses = snapshot.empty
        ? []
        : snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

      if (!compareArrayWithUpdatedAt(responses, newResponses)) {
        console.log({ newResponses });
        setResponses(newResponses);
      }
    });
  }, [interactionsCollectionRef]);

  const pollResults = (() => {
    let results = calculatePollResults(props.card.options, responses);
    if (viewMode === "list") {
      return results.sort((a, b) => b.count - a.count);
    } else {
      return results;
    }
  })();

  console.log({ pollResults, viewMode, card });
  return (
    <div className={classNames("card-output trivia-output options", viewMode)}>
      <OutputOptionsGrid
        card={card}
        highlightCorrect={card?.settings?.outputResultsShown === "all"}
      />
    </div>
  );
};

export default TriviaOutput;
