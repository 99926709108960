// Pass in a userId and we'll get the user's display name

import { doc, getDoc, onSnapshot, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase";

const UserLoader = (props) => {
  //   const user = { displayName: "Bro" };
  const [userData, setUserData] = useState(null);
  const { userId } = props;
  useEffect(() => {
    return onSnapshot(doc(db, "users", userId), (snap) => {
      setUserData(snap.data());
    });
  }, [userId]);

  // fetch user from firebase
  //   const user = props.userId ? await getDoc(doc(db, "users", props.userId)) : null;
  console.log("UserLoader", { props, userData });

  const children = React.Children.map(props.children, (el) => {
    return React.cloneElement(el, { user: userData, ...props });
  });

  return children;
};

export default UserLoader;
