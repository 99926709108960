import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../firebase";
import { useFirebaseAuth } from "../contexts/AuthProvider";
import { useSessionContext } from "./AdminSessionContext";

const AdminLanding = () => {
  const [userSessions, setUserSessions] = useState([]);
  const userId = auth.currentUser?.uid;
  const { createNewEvent } = useSessionContext();

  useEffect(() => {
    onSnapshot(
      query(collection(db, "sessions"), where("createdBy", "==", userId)),
      (snapshot) => {
        setUserSessions(
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
        );
      }
    );
  }, [userId]);
  return (
    <div>
      <header className="toolbar">
        <button className="button" onClick={() => createNewEvent()}>
          Create New Event
        </button>
        <button className="button warning" onClick={() => auth.signOut()}>
          Log Out
        </button>
      </header>
      <h1>Your Sessions</h1>
      <div className="card-gallery">
        {userSessions.map((session) => (
          <Link to={`/${session.id}/admin`}>
            <div className="card outline">
              <h1 className="headline">{session.name}</h1>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default AdminLanding;
