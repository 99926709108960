import { Button } from "@ariakit/react";
import { useFirebaseAuth } from "../contexts/AuthProvider";

const { auth } = require("../firebase");

const AuthGate = (props) => {
  const authProvider = useFirebaseAuth();

  if (auth.currentUser && !auth.currentUser.isAnonymous) {
    return props.children;
  } else if (auth.currentUser && auth.currentUser.isAnonymous) {
    console.log("auth.currentUser", auth.currentUser);
    return <button onClick={() => auth.signOut()}>Sign out</button>;
  } else {
    return (
      props.fallback || (
        <div>
          <Button onClick={() => authProvider.signIn()}>Log in</Button>
        </div>
      )
    );
  }
};

export default AuthGate;
