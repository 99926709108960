import classNames from "classnames";

const OutputOptionsGrid = ({ card, highlightCorrect }) => {
  return (
    <div className={classNames("card-output options", "grid")}>
      {card?.options?.map((option, i) => {
        return (
          <div
            className={classNames(
              "option",
              `option_${i}`,
              highlightCorrect && (option?.correct ? "correct" : "incorrect")
            )}
            key={option?.text + i}
          >
            <div className="text">{option?.text}</div>
          </div>
        );
      })}{" "}
    </div>
  );
};

export default OutputOptionsGrid;
