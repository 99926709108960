import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../firebase";

import { getFirestore, doc, setDoc, onSnapshot } from "firebase/firestore";
import { useSessionContext } from "./AdminSessionContext";
import { useNavigate } from "react-router-dom";
import ButtonSetInput from "./ButtonSetInput";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode, Grid, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/grid";
import "swiper/css/effect-creative";
import ButtonSwipeInput from "./ButtonSwipeInput";

const eventSettings = [
  { label: "In-person", value: "in-person" },
  { label: "Virtual", value: "virtual" },
  { label: "Hybrid", value: "hybrid" },
];
const eventTypes = [
  { label: "Conference", value: "conference" },
  { label: "Workshop", value: "workshop" },
  { label: "Team Meeting", value: "team-meeting" },
  { label: "Q&A", value: "q-and-a" },
  { label: "Hackathon", value: "hackathon" },
  { label: "Meetup", value: "meetup" },
  { label: "Party", value: "party" },
  { label: "Fantasy Draft", value: "fantasy-draft" },
  { label: "Town Hall", value: "town-hall" },
  { label: "Fundraiser", value: "fundraiser" },
  { label: "Silent Auction", value: "silent-auction" },
  { label: "All Hands Meetings", value: "all-hands-meetings" },
  { label: "Presentations", value: "presentations" },
  { label: "Panel Discussion", value: "panel-discussion" },
  { label: "Livestreams", value: "livestreams" },
  { label: "Product Launch", value: "product-launch" },
  { label: "Icebreaker", value: "icebreaker" },
  { label: "Feedback Survey", value: "feedback-survey" },
  { label: "Announcements", value: "announcements" },
  { label: "Trade Show", value: "trade-show" },
  { label: "Book Tour", value: "book-tour" },
  { label: "Lead Gen", value: "lead-gen" },
  { label: "Education", value: "education" },
  { label: "Training", value: "trainings" },
  { label: "Webinar", value: "webinar" },
  { label: "Quiz", value: "quiz" },
];
const eventSizes = [
  { label: "1-4", value: "1-4" },
  { label: "5-9", value: "5-9" },
  { label: "10-24", value: "10-24" },
  { label: "25-99", value: "25-99" },
  { label: "100+", value: "100+" },
];

const EventSetupForm = (props) => {
  const { onSuccess } = props;
  const { register, handleSubmit, setValue, control } = useForm();
  const [loading, setLoading] = useState(true);
  const { sessionId } = useSessionContext();
  const navigate = useNavigate();

  useEffect(() => {
    const docRef = doc(db, "sessions", sessionId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        Object.keys(data).forEach((key) => setValue(key, data[key]));
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [sessionId, setValue]);

  const onSubmit = async (data) => {
    const docRef = doc(db, "sessions", sessionId);
    await setDoc(docRef, data, { merge: true });

    navigate(`/${sessionId}/admin/suggestions?start=complete`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="content">
      <h1>What kind of event?</h1>
      <label htmlFor="name">
        Name
        <input id="name" {...register("name")} placeholder="Name" />
      </label>

      <label>Size</label>
      <ButtonSetInput name="size" options={eventSizes} control={control} />
      <label>Setting</label>
      <ButtonSetInput
        name="setting"
        options={eventSettings}
        control={control}
      />

      <label>Event Type</label>
      <ButtonSetInput name="eventType" options={eventTypes} control={control} />

      {/* <div className="swiper-wrapper">
        <Swiper
          className="event-types-picker"
          grabCursor={true}
          slidesPerView={5}
          direction="vertical"
          spaceBetween={10}
          modules={[Pagination, Autoplay, Grid, FreeMode]}
          pagination={{ clickable: true }}
          //   scrollbar={{ draggable: true }}
          onSwiper={(swiper) => console.log(swiper)}
          onSlideChange={() => console.log("slide change")}
          speed={2500}
          //   freeMode={{ enabled: true }}
          loop={true}
          //   grid={{ fill: "column", rows: 3 }}
          autoplay={{
            delay: 1,
            disableOnInteraction: false,
          }}
        >
          {eventTypes.map((event) => (
            <SwiperSlide>
              <div className="card outline">
                <h1 className="headline">{event.label}</h1>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}

      <button
        className="button large"
        type="submit"
        onClick={handleSubmit(onSubmit)}
      >
        Continue
      </button>
    </form>
  );
};

export default EventSetupForm;
