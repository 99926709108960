import Card from "./Card";
import Draggable from "./Draggable";
import { useSessionContext } from "./AdminSessionContext";
import classNames from "classnames";
import useCardDeck from "../hooks/useCardDeck";

const CardLoader = (props) => {
  const { cardId, deckId } = props;
  const { cards, sendCard, deactivateCard, launchCardEditor } =
    useSessionContext();
  const cardDeck = useCardDeck(deckId);

  const card =
    props.card ||
    (deckId && cardDeck
      ? cardDeck.find((card) => card.id === cardId)
      : cards.find((card) => card.id === cardId));

  if (!card) {
    console.error({ cards, cardDeck, card, cardId });
    return <p>{deckId}</p>;
  }

  return (
    // <Draggable id={cardId}>
    <>
      <Card
        {...props}
        card={card}
        className={classNames([
          props.className,
          { active: card?.active, selected: props.selected },
        ])}
      />
      {props.showControls && !props.buildControls && (
        <div className="controls">
          <button
            className="button secondary"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              launchCardEditor(card);
            }}
          >
            Edit
          </button>
          {!card?.active && (
            <button
              className="button success"
              onClick={() => {
                sendCard(card.id);
              }}
            >
              Show
            </button>
          )}
          {!!card?.active && (
            <button
              className="button warning"
              onClick={() => {
                deactivateCard(card.id);
              }}
            >
              Hide
            </button>
          )}
        </div>
      )}
      {props.buildControls && props.buildControls({ id: card.id, card })}
    </>
    // </Draggable>
  );
};

export default CardLoader;
