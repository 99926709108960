import { useForm } from "react-hook-form";
import { useViewerSessionContext } from "../contexts/ViewerSessionContext";

const BigListGuessForm = ({ card, afterSubmit, renderEditableText }) => {
  const viewerSessionContext = useViewerSessionContext();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    console.log(card, data);
    viewerSessionContext &&
      (await viewerSessionContext.sendInteraction(card.sessionId, card.id, {
        interactionType: "bigList",
        ...data,
        card,
      }));
    reset();
    afterSubmit && afterSubmit(data);
  };

  return (
    <form className="form prompt-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-row">
        {renderEditableText({
          name: "promptPlaceholder",
          value: card.promptPlaceholder,
          placeholder: "Type your guess...",
          // className: "fake-textarea fake-input",
          nonEditable: (
            <textarea
              placeholder="Type your guess..."
              {...register("response")}
            />
          ),
        })}
      </div>
      {renderEditableText({
        name: "submitButtonLabel",
        value: card.submitButtonLabel,
        placeholder: "Send",
        wrapperClass: "button no-hover",
        // className: " editable-input",
        nonEditable: (
          <button className="button" type="submit" disabled={isSubmitting}>
            {card.submitButtonLabel}
          </button>
        ),
      })}

      {isSubmitting && <div>Submitting...</div>}
      {isSubmitted && <div>Submitted.</div>}
    </form>
  );
};

export default BigListGuessForm;
