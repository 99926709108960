import { arrayRemove, arrayUnion, doc, setDoc } from "firebase/firestore";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { db, auth } from "../firebase";

function useTrackSession() {
  const { sessionId } = useParams();
  const userId = auth.currentUser?.uid;

  useEffect(() => {
    if (sessionId && userId) {
      const sessionRef = doc(db, "sessions", sessionId);
      setDoc(
        sessionRef,
        {
          activeUsers: arrayUnion(userId),
        },
        { merge: true }
      );
      const removeUser = () => {
        setDoc(
          sessionRef,
          {
            activeUsers: arrayRemove(userId),
          },
          { merge: true }
        );
      };

      window.addEventListener("beforeunload", removeUser);

      return () => {
        removeUser();
        window.removeEventListener("beforeunload", removeUser);
      };
    }
  }, [sessionId, userId]);
}

export default useTrackSession;
