import React from "react";

const CountdownProgressBar = ({ duration }) => {
  return (
    <div className="countdown-container">
      <div
        className="countdown-progress"
        style={{ animationDuration: `${duration}s` }}
      />
    </div>
  );
};

export default CountdownProgressBar;
