import { Link, Outlet, generatePath, useParams } from "react-router-dom";
import { useSessionContext } from "./AdminSessionContext";

const OnboardingWrapper = () => {
  const params = useParams();
  const { sessionData } = useSessionContext();
  console.log(params);
  return (
    <div className="onboarding-wrapper">
      <div className="toolbar">
        <span>{sessionData.name}</span>
        {/* {params["*"] === "name" && (
          <Link
            className="button"
            to={generatePath("/:sessionId/admin/start/types", params)}
          >
            Next: Card Types
          </Link>
        )} */}
        {params["*"] === "types" && (
          <Link
            className="button"
            to={generatePath("/:sessionId/admin/start/design", params)}
          >
            Next: Design
          </Link>
        )}
        {params["*"] === "design" && (
          <Link
            className="button"
            to={generatePath("/:sessionId/admin/start/context", params)}
          >
            Next: Context
          </Link>
        )}
        {params["*"] === "context" && (
          <Link
            className="button"
            to={generatePath("/:sessionId/admin/start/decks", params)}
          >
            Next: Sample Cards
          </Link>
        )}
        {params["*"] === "decks" && (
          <Link
            className="button"
            to={generatePath("/:sessionId/admin?start=complete", params)}
          >
            Finish setup
          </Link>
        )}
      </div>
      <div className="padding">
        <Outlet />
      </div>
    </div>
  );
};
export default OnboardingWrapper;
