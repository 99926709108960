import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useViewerSessionContext } from "../contexts/ViewerSessionContext";
import { useSessionContext } from "./AdminSessionContext";
import UserLoader from "./UserLoader";
import { faUser } from "@fortawesome/pro-solid-svg-icons";

const LobbyUser = (props) => {
  const { user } = props;
  console.log("LobbyUser", { props });
  if (!user) return null;
  return (
    <div className="lobby-user">
      {user.id} {user?.displayName}
    </div>
  );
};

const LobbyOutput = () => {
  //   const viewerSessionContext = useViewerSessionContext();
  const adminSessionContext = useSessionContext();

  const sessionData = adminSessionContext?.sessionData;

  return (
    <div className="lobby-output">
      {sessionData?.activeUsers?.map((id) => (
        <div key={id} className="lobby-user inset padded">
          <FontAwesomeIcon icon={faUser} />
          <UserLoader userId={id} key={id}>
            <LobbyUser userId={id} />
          </UserLoader>
        </div>
      ))}
    </div>
  );
};

export default LobbyOutput;
