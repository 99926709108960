import classNames from "classnames";
import Card from "./Card";
import { useSessionContext } from "./AdminSessionContext";

const cardContent = [
  {
    id: "prompt",
    cardType: "prompt",
    duration: 30,
    headline: "What have you been working on this week?",
  },
  {
    id: "poll",
    cardType: "poll",
    duration: 10,
    headline: "What's your favorite color?",
    text: "Choose one and only one.",
    options: [
      { text: "Blue", hashtag: "#option1" },
      { text: "Green", hashtag: "#option2" },
      { text: "Red", hashtag: "#option3" },
      { text: "Orange", hashtag: "#option4" },
    ],
  },
  {
    id: "trivia",
    cardType: "trivia",
    duration: 10,
    headline: "Quick Trivia",
    text: "Who commissioned the Hoover Dam?",
    options: [
      { text: "Option 1", hashtag: "#option1" },
      { text: "Option 2", hashtag: "#option2" },
      { text: "Option 3", hashtag: "#option3" },
    ],
  },
  {
    id: "signup",
    cardType: "signup",
    headline: "Are these ideas intriguing to you?",
    text: "Subscribe to our newsletter.",
    dismissable: true,
  },
  {
    id: "wyr",
    cardType: "wyr",
    headline: "Would you rather?",
    options: [
      { text: "Have rain on your wedding day?", hashtag: "#option1" },
      {
        text: "Have 10,000 spoons when all you need is a knife?",
        hashtag: "#option2",
      },
    ],
  },
];

const ThemePicker = ({}) => {
  const { updateSession, sessionData } = useSessionContext();

  const themes = [
    "theme-default",
    "theme-dark",
    "theme-line",
    "theme-colorful",
    "theme-slido",
    "theme-pixel",
    "theme-celtics",
    "theme-loud",
    "theme-fashion",
  ];

  const updateTheme = async (theme) => {
    await updateSession({ theme });
  };

  return (
    <div className="theme-picker">
      <div className="content">
        <h1>Theme</h1>
        <p>Complete the look.</p>
      </div>

      <div className="cards ">
        {themes.map((theme, i) => {
          return (
            <div
              className={classNames([
                theme,
                "theme-picker-card",
                { selected: theme === sessionData.theme },
              ])}
            >
              <div
                className={classNames([
                  { selected: theme === sessionData.theme },
                ])}
              >
                <div className="row">
                  <div className="subtle">{theme}</div>
                  <button
                    className={classNames(["button"], {
                      success: theme === sessionData.theme,
                    })}
                    onClick={() => updateTheme(theme)}
                  >
                    {theme === sessionData.theme ? "Selected" : "Select"}
                  </button>
                </div>
                <Card
                  clickable
                  noninteractive
                  onClick={() => updateTheme(theme)}
                  card={
                    cardContent[
                      i < cardContent.length ? i : i % cardContent.length
                    ]
                  }
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ThemePicker;
