import { collection, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase";
import classNames from "classnames";
import { useForm } from "react-hook-form";

const BigListDisplay = ({
  card,
  renderEditableText,
  afterSubmit,
  sendInteraction,
}) => {
  const [allUserInteractions, setAllUserInteractions] = useState([]);
  const [allGuesses, setAllGuesses] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitted },
    reset,
  } = useForm();
  const onSubmit = async (data) => {
    console.log(card, data);
    await sendInteraction(card.sessionId, card.id, {
      interactionType: "bigList",
      ...data,
      card,
    });
    reset();
    afterSubmit && afterSubmit(data);
  };

  useEffect(() => {
    if (card?.sessionId) {
      const interactionsRef = collection(
        db,
        "sessions",
        card.sessionId,
        "cards",
        card.id,
        "interactions"
      );

      onSnapshot(
        query(interactionsRef, where("interactionType", "==", "bigList")),
        (snapshot) => {
          const newData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // console.log(newData);
          setAllUserInteractions(newData);
          setAllGuesses(
            newData.map((interaction) => {
              return interaction.response?.toLowerCase();
            })
          );
        }
      );
    }
  }, [card]);
  // console.log({ allUserInteractions, card, allGuesses });
  return (
    <div className="big-list-display">
      {card?.options && (
        <div className="big-list-enties">
          {card.options.map((item, i) => (
            <div
              key={item.text + i + item.hint}
              className={classNames(
                "big-list-entry",
                allGuesses.includes(item.text?.toLowerCase())
                  ? "answered"
                  : "unanswered"
              )}
            >
              <div className="hint">
                {renderEditableText({
                  name: `options.${i}.hint`,
                  value: card.promptPlaceholder,
                  placeholder: "Hint",
                  // className: "fake-textarea fake-input",
                  nonEditable: item.hint,
                })}
              </div>
              <div className="answer">
                {renderEditableText({
                  name: `options.${i}.text`,
                  value: card.promptPlaceholder,
                  placeholder: "Type your guess...",
                  // className: "fake-textarea fake-input",
                  nonEditable: allGuesses.includes(item.text?.toLowerCase())
                    ? item.text
                    : "?",
                })}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BigListDisplay;
