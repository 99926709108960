import { useContext } from "react";
import { useSessionContext } from "./AdminSessionContext";
import { useSearchParams } from "react-router-dom";
import classNames from "classnames";
import Card from "./Card";

export const cardTypes = [
  {
    name: "Title",
    cardType: "lobby",
    description:
      "See a welcome message and the names of all the people in the room.",
    gptDescription:
      "Lobby shows a welcome message in the headline and a list of users who have joined. Typically we will place one of these at the start of an event.",
    sampleData: [{ cardType: "lobby", headline: "Welcome!" }],
    defaultData: {
      headline: "Welcome!",
    },
  },
  {
    name: "Simple",
    cardType: "simple",
    description: "Simple content, no interactivtiy.",
    gptDescription:
      "No interactivity. Only to be used if you want to show a message to the audience.",
    sampleData: [
      { cardType: "simple", headline: "Copyright 2023 Crowdwork Inc." },
    ],
    defaultData: {
      cardType: "simple",
      headline: "",
    },
  },
  {
    name: "Prompt",
    cardType: "prompt",
    description:
      "A prompt is a question or statement that the user will respond to.",
    gptDescription:
      "We put a question or statement in the headline and a text box for the user to respond. This can be for Q&A, or to collect feedback, or anything you might need free response for. The host will be able to review and approve responses before they are shown to the audience. The buttonText should reflect the action you want the user to take.",
    sampleData: [
      { cardType: "prompt", headline: "How are you feeling today?" },
      { cardType: "prompt", headline: "What's new?" },
    ],
    defaultData: {
      cardType: "prompt",
      headline: "",
      buttonText: "Submit",
      placeholderText: "Enter your response here",
      confirmationText: "Thank you for your response!",
    },
  },
  {
    name: "Poll",
    cardType: "poll",
    description: "A poll is a multiple choice question.",
    gptDescription:
      "A multiple choice question with up to 10 options. If there's supposed to be a correct answer, use a trivia card instead.",
    sampleData: [
      {
        cardType: "poll",
        headline: "What is your favorite color?",
        options: [{ text: "Red" }, { text: "Blue" }, { text: "Green" }],
        settings: {
          outputPollShowNumbers: "percentage",
          outputViewMode: "horizontal-bars",
        },
      },
    ],
    defaultData: {
      cardType: "poll",
      headline: "",
      options: [{ text: "" }, { text: "" }],
      confirmationText: "Good choice.",
      settings: {
        outputPollShowNumbers: "percentage",
        outputViewMode: "horizontal-bars",
        freeResponse: false,
      },
    },
  },
  {
    name: "Signup",
    cardType: "signup",
    description:
      "Users can sign up for a list, register interest, or ask to receive more information.",
    gptDescription:
      "A signup card is a way to collect email addresses. It can be used to sign up for a list, register interest, or ask to receive more information. headline should be the primary call to action, and buttonText and confirmationText should match.",
    sampleData: [
      {
        cardType: "signup",
        headline: "Can we count on your help getting out the vote?",
        buttonText: "I'm in",
        cancelButtonText: "No thanks",
        confirmationText: "Thank you!",
        settings: {
          hideSavedEmail: true,
          saveEmail: true,
        },
      },
    ],
    defaultData: {
      cardType: "signup",
      headline: "",
      cancelButtonText: "",
      buttonText: "Sign up",
      confirmationText: "Thank you.",
      settings: {
        hideSavedEmail: true,
        saveEmail: true,
      },
    },
  },
  {
    name: "Trivia",
    cardType: "trivia",
    description: "A trivia question is a multiple choice question.",
    gptDescription:
      "A multiple choice question. The question should be in the headline and the possible answers in the options. Only one answer should be correct.",
    sampleData: [
      {
        cardType: "trivia",
        headline: "What goes up but never comes down?",
        options: [
          { text: "A helicopter" },
          { text: "A balloon" },
          { text: "Your age", correct: true },
        ],
      },
    ],
    defaultData: {
      cardType: "trivia",
      headline: "",
      options: [{ text: "" }, { text: "" }],
      confirmationText: "Received",
    },
  },
  {
    name: "Big List",
    cardType: "big-list",
    description:
      "Big List is a game where users collaborate to fill in entries on a themed list.",
    gptDescription:
      "Big List is a game where users collaborate to fill in entries on a themed list. The headline should be the theme and the options should be the answers. Keep it to no more than 20 options.",
    sampleData: [
      {
        cardType: "big-list",
        headline: "Best Picture Winners of the 1990s",
        options: [
          { text: "Driving Miss Daisy", hint: "1990" },
          { text: "Dances with Wolves", hint: "1991" },
          { text: "The Silence of the Lambs", hint: "1992" },
          { text: "Unforgiven", hint: "1993" },
          { text: "Schindler's List", hint: "1994" },
          { text: "Forrest Gump", hint: "1995" },
          { text: "Braveheart", hint: "1996" },
          { text: "The English Patient", hint: "1997" },
          { text: "Titanic", hint: "1998" },
          { text: "Shakespeare in Love", hint: "1999" },
        ],
      },
    ],
    defaultData: {
      cardType: "big-list",
      headline: "Best Picture Winners of the 1990s",
      options: [
        { text: "Driving Miss Daisy", hint: "1990" },
        { text: "Dances with Wolves", hint: "1991" },
        { text: "The Silence of the Lambs", hint: "1992" },
        { text: "Unforgiven", hint: "1993" },
        { text: "Schindler's List", hint: "1994" },
        { text: "Forrest Gump", hint: "1995" },
        { text: "Braveheart", hint: "1996" },
        { text: "The English Patient", hint: "1997" },
        { text: "Titanic", hint: "1998" },
        { text: "Shakespeare in Love", hint: "1999" },
      ],
      submitButtonLabel: "Guess",
    },
  },
  {
    name: "Break",
    cardType: "break",
    description: "This is used to create a break in the flow of cards.",
    sampleData: [{}],
    defaultData: {},
  },
];

const CardTypePicker = () => {
  const sessionData = useSessionContext();
  let [searchParams, setSearchParams] = useSearchParams();

  const activeType = searchParams.get("type");

  console.log({ sessionData });

  return (
    <div className="card-type-picker-wrapper">
      <div className="card-type-picker-header content">
        <h1>Card Type Picker</h1>
        <p>
          We have lots of interactive card types available, but you can turn
          some off if they don't seem right for your event.
        </p>
      </div>
      <div className="card-type-picker">
        <div className="card-type-list">
          {cardTypes.map((cardType) => {
            return (
              <div
                className={classNames("card-type-tile", {
                  active: activeType === cardType.cardType,
                })}
                onClick={() => {
                  setSearchParams({ type: cardType.cardType });
                }}
              >
                <h2>{cardType.name}</h2>
                <p>{cardType.description}</p>
              </div>
            );
          })}
        </div>
        <div className="card-type-preview card-canvas">
          <div className="padding cards">
            {activeType && (
              <>
                {cardTypes
                  .find((cardType) => cardType.cardType === activeType)
                  .sampleData.map((cardData, index) => (
                    <Card key={index} card={cardData} />
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTypePicker;
