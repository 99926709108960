import AdminCards from "./AdminCards";
import Card from "./Card";

import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  defaultAnimateLayoutChanges,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { useSessionContext } from "./AdminSessionContext";
import CardLoader from "./CardLoader";
import SortableItem from "./SortableItem";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import AdminControls from "./AdminControls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faLightbulb,
  faRectangleVerticalHistory,
} from "@fortawesome/pro-solid-svg-icons";
import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const animateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true });

function Droppable(props) {
  const { id = "", items = [], disabled = false, style = {}, children } = props;
  const { attributes, listeners, setNodeRef, transition } = useSortable({
    id,
    data: {
      type: "container",
      children: items,
    },
    animateLayoutChanges,
  });

  return (
    <div
      ref={disabled ? undefined : setNodeRef}
      style={{
        ...style,
        transition,
      }}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
}

const Timeline = (props) => {
  const [, setSearchParams] = useSearchParams();
  const { sessionData, launchCardEditor, sendCard, deactivateCard } =
    useSessionContext();
  const queue = props.queue || sessionData?.cardOrder || [];
  console.log("Timeline", {
    queue: props.queue,
    cardOrder: sessionData?.cardOrder,
    finalQueue: queue,
  });
  const navigate = useNavigate();
  const [showTimeline, setShowTimeline] = useState(true);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const params = useParams();
  const selectedCardId = params?.cardId;
  return (
    <div className="timeline-wrapper">
      <AnimatePresence>
        <div className="timeline">
          {showTimeline && (
            <motion.div
              className="queued"
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              layout
            >
              {queue.length === 0 && (
                <p>Your timeline is empty. Try adding some cards.</p>
              )}
              <SortableContext
                id="queue"
                items={props?.queue || sessionData.queue || []}
                strategy={horizontalListSortingStrategy}
              >
                <Droppable
                  id="queued"
                  key="queued"
                  items={props?.queue || sessionData.queue || []}
                >
                  <div className="entries">
                    {queue.map((id) => (
                      <SortableItem
                        id={id}
                        key={id}
                        onClick={() => console.log("FOO")}
                      >
                        <div data-no-dnd={true} className="entry">
                          <Link to={`live/${id}`}>
                            <CardLoader
                              selected={id === selectedCardId}
                              key={id}
                              cardId={id}
                              onClick={() => {
                                // launchCardEditor({ id });
                                console.log("NAVIGATE", id);
                                // navigate(id);
                              }}
                            />
                          </Link>
                        </div>
                      </SortableItem>
                    ))}
                  </div>
                </Droppable>
              </SortableContext>
              <div className="add-new ">
                <Card
                  className="empty-card"
                  onClick={() => {
                    setSearchParams({ action: "new" });
                  }}
                  card={{
                    cardType: "prompt",
                    headline: "New Card +",
                  }}
                />
              </div>
            </motion.div>
          )}
          {showSuggestions && (
            <motion.div
              className="suggestions"
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              exit={{ height: 0 }}
              layout
            >
              <div className="entries">
                {[
                  { cardType: "prompt", headline: "Suggestion 1" },
                  { cardType: "prompt", headline: "Suggestion 2" },
                  { cardType: "prompt", headline: "Suggestion 3" },
                  { cardType: "prompt", headline: "Suggestion 4" },
                  { cardType: "prompt", headline: "Suggestion 5" },
                ].map((card) => (
                  <div className="entry">
                    <CardLoader card={card} />
                  </div>
                ))}
              </div>
            </motion.div>
          )}
          <div className="controls">
            <button
              className="button timeline-button"
              onClick={() => setShowTimeline(!showTimeline)}
            >
              <span className="fa-layers fa-fw fa-lg">
                <FontAwesomeIcon
                  icon={faRectangleVerticalHistory}
                  fixedWidth
                  transform={showTimeline ? "up-2" : ""}
                />
                <FontAwesomeIcon
                  icon={showTimeline ? faCaretDown : null}
                  fixedWidth
                  flip={showTimeline ? undefined : "vertical"}
                  transform={showTimeline ? "down-11 shrink-6" : ""}
                />
              </span>

              {/* <span className="badge">20</span> */}
            </button>
            <button
              className="button bright suggestion-button"
              onClick={() => setShowSuggestions(!showSuggestions)}
            >
              <span className="fa-layers fa-fw fa-lg">
                <FontAwesomeIcon
                  icon={faLightbulb}
                  fixedWidth
                  transform={showSuggestions ? "up-2" : ""}
                />
                <FontAwesomeIcon
                  icon={showSuggestions ? faCaretDown : null}
                  fixedWidth
                  flip={showSuggestions ? undefined : "vertical"}
                  transform={showSuggestions ? "down-11 shrink-6" : ""}
                />
              </span>

              <span className="badge">20</span>
            </button>
            <AdminControls />
          </div>
        </div>
      </AnimatePresence>
    </div>
  );
};

export default Timeline;
