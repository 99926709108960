import { useParams } from "react-router-dom";

import Card from "../Card";
import classNames from "classnames";
import "./Viewer.css";
import { useFirebaseAuth } from "../../contexts/AuthProvider";
import ViewerEntryForm from "./ViewerEntryForm";
import ViewerLoggedIn from "./ViewerLoggedIn";
import ViewerEmptyState from "./ViewerEmptyState";
import { useViewerSessionContext } from "../../contexts/ViewerSessionContext";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { QRCodeSVG } from "qrcode.react";
import { useSessionContext } from "../AdminSessionContext";

const Output = ({ showHeader = true }) => {
  const { sessionId } = useParams();

  const { activeCards, interactions, sessionData } = useSessionContext();

  const { user } = useFirebaseAuth();

  const cards = activeCards;
  console.log({ user, cards, interactions });

  return (
    <div
      className={classNames([
        "output-screen",
        sessionData?.theme ? sessionData.theme : "theme-default",
      ])}
    >
      <motion.div className={classNames(["output-cards"])}>
        <AnimatePresence mode="popLayout">
          {cards &&
            cards.map((card, index) => (
              <motion.div
                className="output-card-wrapper"
                layout="position"
                key={card.id}
                // style={{ position: "relative" }}
                initial={{ x: 50, opacity: 0 }}
                animate={{ x: 0, opacity: 1, zIndex: 10 }}
                exit={{ x: -150, opacity: 0, scale: 0.9, zIndex: 5 }}
                transition={{ duration: 1, ease: "easeInOut" }}
              >
                <Card
                  key={index}
                  output={true}
                  card={{ ...card, sessionId }}
                  noninteractive
                />
              </motion.div>
            ))}

          <ViewerEmptyState />
        </AnimatePresence>
      </motion.div>
      {showHeader && (
        <header className="join-cta">
          <div>
            Join now!{" "}
            <span className="fake-link">{`side.moe/${sessionId}`}</span>
          </div>
          <QRCodeSVG value={`https://side.moe/${sessionId}`} size="200" />
        </header>
      )}
    </div>
  );
};

export default Output;
