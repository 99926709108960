import React from "react";
import ReactDOM, { createRoot } from "react-dom/client";
import "./index.css";
import Wrapper from "./components/Admin";
import Viewer from "./components/viewer/Viewer";
import { SessionProvider } from "./components/AdminSessionContext";
import CardPlayground from "./components/CardPlayground";
import CardDecks from "./components/CardDecks";
import CardDeck from "./components/CardDeck";
import Cards from "./components/AdminCards";
import Suggestions from "./components/Suggestions";
import EventSettings from "./components/EventSettings";
import OnboardingWrapper from "./components/OnboardingWrapper";
import CardTypePicker from "./components/CardTypePicker";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  useParams,
} from "react-router-dom";
import "./App.css";
import ThemePicker from "./components/ThemePicker";
import EventName from "./components/EventName";
import AuthProvider from "./contexts/AuthProvider";
import ViewerSessionProvider from "./contexts/ViewerSessionContext";
import Output from "./components/viewer/Output";
import CardGallery from "./components/CardGallery";
import Responses from "./components/Responses";
import EventSetupForm from "./components/EventSetupForm";
import AdminLanding from "./components/AdminLanding";
import AuthGate from "./components/AuthGate";
import AdminLive from "./components/AdminLive";
import ContentLibrary from "./components/ContentLibrary";

const router = createBrowserRouter([
  {
    path: "/cards",
    element: <CardPlayground />,
  },
  {
    path: "/admin",
    element: (
      <AuthGate>
        <SessionProvider>
          <AdminLanding />
        </SessionProvider>
      </AuthGate>
    ),
  },
  {
    path: "/:sessionId/admin/start/*",
    element: (
      <SessionProvider>
        <OnboardingWrapper />
      </SessionProvider>
    ),
    children: [
      {
        path: "",
        element: <Navigate to="name" />,
      },
      {
        path: "form",
        element: (
          <div>
            <EventSetupForm />
          </div>
        ),
      },
      {
        path: "name",
        element: (
          <div>
            <EventName />
          </div>
        ),
      },
      {
        path: "types",
        element: (
          <div>
            <CardTypePicker />
          </div>
        ),
      },
      {
        path: "decks/:deckId",
        element: (
          <div>
            <CardDeck />
          </div>
        ),
      },
      {
        path: "decks",
        element: (
          <div>
            <CardDecks />
          </div>
        ),
      },
      {
        path: "context",
        element: (
          <div>
            <Suggestions />
          </div>
        ),
      },
      {
        path: "design",
        element: (
          <div>
            <ThemePicker />
          </div>
        ),
      },
    ],
  },
  {
    path: "/:sessionId/admin",
    element: (
      <SessionProvider>
        <Wrapper />
      </SessionProvider>
    ),
    children: [
      {
        path: "live/:cardId?",
        element: <AdminLive />,
      },
      {
        path: "decks/:deckId",
        element: <CardDeck useParam />,
      },
      {
        path: "library/:libraryId?",
        element: <ContentLibrary />,
      },
      {
        path: "decks",
        element: <CardDecks />,
      },
      {
        path: "suggestions",
        element: <Suggestions />,
      },
      {
        path: "context",
        element: <Suggestions />,
      },
      {
        path: "settings",
        element: <EventSettings />,
      },
      {
        path: "",
        element: <CardGallery />,
      },
    ],
  },
  {
    path: "/:sessionId/output",
    element: (
      <SessionProvider>
        <Output />
      </SessionProvider>
    ),
  },
  {
    path: "/:sessionId",
    element: (
      <SessionProvider>
        <ViewerSessionProvider>
          <Viewer />
        </ViewerSessionProvider>
      </SessionProvider>
    ),
  },
  {
    path: "",
    element: <p></p>,
  },
]);

createRoot(document.getElementById("root")).render(
  <AuthProvider>
    <RouterProvider router={router} />
  </AuthProvider>
);

// reportWebVitals();
