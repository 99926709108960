import React, { useEffect, useState } from "react";
import Card from "./Card";
import { Timestamp, doc, getDoc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useSessionContext } from "./AdminSessionContext";
import { Button } from "@ariakit/react";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import classNames from "classnames";
import { useParams } from "react-router-dom";
import Responses from "./Responses";
import CardIdeas from "./CardIdeas";
import { cardTypes } from "./CardTypePicker";
import Options from "./Options";
import EditableOptions from "./EditableOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPlusCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";

const CardEditor = ({ cardId, closePortal, deckId }) => {
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty, isValid },
    watch,
  } = useForm({
    defaultValues: {
      internalName: "",
      useTimer: false,
      timerLength: 30,
      showResults: "immediately",
      options: [],
    },
    mode: "onChange",
  });
  console.log(errors);
  // const { deckId } = useParams();
  const { sessionData, sessionId } = useSessionContext();
  const [canvasMode, setCanvasMode] = useState("edit");
  const [activeTab, setActiveTab] = useState("settings");
  const [card, setCard] = useState(null);

  const cardDocRef = deckId
    ? doc(db, "cardDecks", deckId, "cards", cardId)
    : doc(db, "sessions", sessionId, "cards", cardId);

  useEffect(() => {
    if (card) return;
    onSnapshot(cardDocRef, (doc) => {
      if (!doc.exists()) return;
      setCard({ id: doc.id, ...doc.data() });
    });
  }, [cardDocRef, card]);

  useEffect(() => {
    reset({ ...card });
  }, [card, reset]);

  const onSubmit = async (data) => {
    console.log("submitting", data);
    // clean undefined values
    Object.keys(data).forEach(
      (key) => data[key] === undefined && delete data[key]
    );
    await setDoc(
      cardDocRef,
      { ...data, updatedAt: Timestamp.now() },
      { merge: true }
    )
      .then(() => {
        getDoc(cardDocRef).then((doc) => {
          if (!doc.exists()) return;
          setCard({ id: doc.id, ...doc.data() });
        });
      })
      .catch(console.error);
  };

  const watchData = watch();

  const previewCardData = { ...card, ...watchData };

  const optionsFields = useFieldArray({ control, name: "options" });

  //   const watchValues = {};
  if (!card || !card.id) return <div>Loading...</div>;

  return (
    <div className={classNames("card-editor")} key="card-editor">
      <div
        className={classNames(
          "card-canvas",
          sessionData.theme || "theme-default"
          // canvasMode === "preview" ? sessionData.theme : "theme-default",
          // canvasMode === "preview" && "preview"
        )}
      >
        {/* <div className="row toolbar centered">
          <Button
            className={classNames([
              "tab",
              canvasMode === "edit" ? "active" : "inactive",
            ])}
            type="button"
            onClick={() => setCanvasMode("edit")}
          >
            Edit
          </Button>

          <Button
            className={classNames([
              "tab",
              {
                active: canvasMode === "preview",
                inactive: canvasMode !== "preview",
              },
            ])}
            type="button"
            onClick={() => setCanvasMode("preview")}
          >
            Preview
          </Button>
        </div> */}
        <div className="padding">
          <div className="preview-input">
            {canvasMode === "edit" ? (
              <FormProvider {...{ control, register }}>
                <Card card={previewCardData} editable={"editable"} />
                <Card
                  card={previewCardData}
                  editable={"editable"}
                  userState={{ submitted: true }}
                />
              </FormProvider>
            ) : (
              <Card card={previewCardData} control={control} />
            )}
          </div>
          {/* <div className="preview-output">
            <div className="output-screen">
              <div className="output-card-wrapper">
                {canvasMode === "edit" ? (
                  <FormProvider {...{ control, register }}>
                    <Card
                      card={previewCardData}
                      editable={"editable"}
                      output={true}
                    />
                  </FormProvider>
                ) : (
                  <Card
                    card={previewCardData}
                    control={control}
                    output={true}
                  />
                )}
              </div>
            </div> 
          </div>*/}
          {/* <div>
            <div className="card micro" data-card-type={card.cardType}></div>
          </div> */}
        </div>
      </div>
      <div className="card-settings">
        {/* <div className="toolbar centered">
          <Button
            className={classNames([
              "tab",
              activeTab === "suggestions" ? "active" : "inactive",
            ])}
            onClick={() => setActiveTab("suggestions")}
          >
            Suggestions
          </Button>
          <Button
            className={classNames([
              "tab",
              activeTab === "settings." ? "active" : "inactive",
            ])}
            onClick={() => setActiveTab("settings")}
          >
            Settings{" "}
          </Button>
          <Button
            className={classNames([
              "tab",
              activeTab === "responses" ? "active" : "inactive",
            ])}
            onClick={() => setActiveTab("responses")}
          >
            Responses{" "}
            {card.totalInteractions > 0 && (
              <span className="badge">{card.totalInteractions}</span>
            )}
          </Button>
        </div> */}
        <div className="">
          {activeTab === "suggestions" && (
            <CardIdeas cardId={cardId} cardType={previewCardData.cardType} />
          )}
          {activeTab === "settings" && (
            <form>
              <div className="form-flex">
                <div className="form-controls">
                  <button
                    className="button"
                    type="submit"
                    onClick={() => {
                      console.log("Clicked");
                      handleSubmit(onSubmit)();
                    }}
                    disabled={!isDirty || !isValid}
                  >
                    Save
                  </button>
                  <button
                    className="button success"
                    type="submit"
                    onClick={async () => {
                      console.log("Clicked");
                      await handleSubmit(onSubmit)();
                      closePortal && closePortal();
                    }}
                    disabled={!isDirty || !isValid}
                  >
                    Save and Close
                  </button>
                </div>
                <div className="padding">
                  <div className="field-group">
                    <label className="label" htmlFor="cardType">
                      Card Type
                    </label>
                    <select {...register("cardType")}>
                      {cardTypes.map((cardType) => (
                        <option value={cardType.cardType}>
                          {cardType.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <h2>Content</h2>

                  <div className="field multirow">
                    <label className="label" htmlFor="headline">
                      Headline
                    </label>
                    <input {...register("headline")} type="text" />
                  </div>
                  <div className="field multirow">
                    <label className="label" htmlFor="text">
                      Content
                    </label>
                    <input {...register("text")} type="text" />
                  </div>

                  {previewCardData.cardType.match(/poll|big-list|trivia/) && (
                    <>
                      <h3>Options</h3>
                      <div className="edit-options">
                        {(optionsFields?.fields || previewCardData.options).map(
                          (option, i) => (
                            <div
                              key={option.id ?? option.text ?? i}
                              className={`edit-option ${
                                option.text === "selectedAnswer"
                                  ? "selected"
                                  : ""
                              }`}
                            >
                              {/* <span>{option.text}</span> */}
                              <span className="option-text">
                                <input {...register(`options.${i}.text`)} />
                              </span>
                              <div className="editable-controls">
                                <div
                                  onClick={() => {
                                    optionsFields.remove(i);
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    style={{
                                      opacity: 0.2,
                                      ":hover": { opacity: 1 },
                                    }}
                                  />
                                </div>
                                {previewCardData.cardType === "trivia" && (
                                  <div
                                    onClick={() => {
                                      optionsFields.update(i, {
                                        ...option,
                                        correct: !option.correct,
                                      });
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      style={{
                                        opacity: option.correct ? 1 : 0.2,
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <button
                        className="button success icon"
                        onClick={() => {
                          console.log({ optionsFields });
                          optionsFields.append({ text: "" });
                        }}
                        type="button"
                      >
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          style={{ marginRight: 4 }}
                        />
                        Add option
                      </button>
                    </>
                  )}

                  <h2>User Input</h2>

                  {previewCardData.cardType.match(
                    /prompt|poll|big-list|signup/
                  ) && (
                    <div className="field-group">
                      <div className="field">
                        <label
                          className="label"
                          htmlFor="settings.afterUserInteraction"
                        >
                          After user submits
                        </label>
                        <select {...register("settings.afterUserInteraction")}>
                          <option value="dismiss">Dismiss</option>
                          <option value="resubmit">Submit again</option>
                          <option value="results">Show results</option>
                        </select>
                        {/* <FormError name={form.names.useTimer} className="error" /> */}
                      </div>
                    </div>
                  )}

                  <h2>Results</h2>
                  <div className="field multirow">
                    {previewCardData.cardType.match(/poll/) && (
                      <div className="field-group">
                        <label
                          className="label"
                          htmlFor="settings.outputPollShowNumbers"
                        >
                          Show result numbers
                        </label>
                        <select {...register("settings.outputPollShowNumbers")}>
                          <option value="percentage">Percentage</option>
                          <option value="count">Actual counts</option>
                          <option value="none">None</option>
                        </select>
                        {/* <FormError name={form.names.useTimer} className="error" /> */}
                      </div>
                    )}
                    {previewCardData.cardType.match(/poll/) && (
                      <div className="field-group">
                        <div className="field">
                          <label
                            className="label"
                            htmlFor="settings.outputViewMode"
                          >
                            Results appearance
                          </label>
                          <select {...register("settings.outputViewMode")}>
                            <option value="horizontal-bars">
                              Horizontal bars
                            </option>
                            <option value="pie-chart">Pie chart</option>
                            <option value="list">List</option>
                            <option value="top-choice">Top choice</option>
                          </select>
                          {/* <FormError name={form.names.useTimer} className="error" /> */}
                        </div>
                      </div>
                    )}
                    {/* <EditableOptions
                        card={previewCardData}
                        editable
                        control={control}
                        // renderEditableText={(props) => <input {...props} />}
                      /> */}
                    {previewCardData.cardType.match(/prompt/) && (
                      <div className="field-group">
                        <div className="field">
                          <label
                            className="label"
                            htmlFor="settings.outputViewMode"
                          >
                            Results appearance
                          </label>
                          <select {...register("settings.outputViewMode")}>
                            <option value="single">One at a time</option>
                            <option value="list">List</option>
                            <option value="word-cloud">Word cloud</option>
                            <option value="summary">AI Summary</option>
                          </select>
                          {/* <FormError name={form.names.useTimer} className="error" /> */}
                        </div>
                      </div>
                    )}
                    {previewCardData.cardType.match(/prompt/) && (
                      <div className="field-group">
                        <div className="field">
                          <label
                            className="label"
                            htmlFor="settings.outputResultsShown"
                          >
                            Results shown
                          </label>
                          <select {...register("settings.outputResultsShown")}>
                            <option value="none">None</option>
                            <option value="all">All</option>
                            <option value="approved">Approved</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
          {activeTab === "responses" && (
            <Responses cardId={cardId} sessionId={sessionId} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardEditor;
