import Card from "./Card";
import CardGallery from "./CardGallery";
import { cardTypes } from "./CardTypePicker";

const CardIdeas = ({ cardId, cardType }) => {
  const cardTypeData = cardTypes.find(
    (cardTypeEx) => cardTypeEx.cardType === cardType && !!cardTypeEx?.sampleData
  );
  console.log({ cardType, cardTypes, cardTypeData });
  return (
    <div className="card-ideas">
      <CardGallery
        cards={cardTypeData?.sampleData.map((card, i) => ({
          ...card,
          id: `card${i}`,
        }))}
        showViewModePicker={false}
        onClick={() => {}}
        buildControls={() => {
          return (
            <div className="button-row">
              <button
                className="button success"
                onClick={() => alert("didn't build it yet!")}
              >
                Apply content
              </button>
            </div>
          );
        }}
      />
    </div>
  );
};

export default CardIdeas;
