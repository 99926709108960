import { useEffect, useState } from "react";
import { useSessionContext } from "./AdminSessionContext";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import Wordcloud from "@visx/wordcloud/lib/Wordcloud";
import { Text } from "@visx/text";
import { scaleLog } from "@visx/scale";
import { compareArrayWithUpdatedAt } from "../util/compareArrays";
import WordcloudOutput from "./WordcloudOutput";

const PromptOutput = (props) => {
  const [responses, setResponses] = useState([]);
  const sessionContext = useSessionContext();
  console.log("PromptOutput", { props });
  const card = props.card;
  const sessionId = card.sessionId;
  const cardId = card.id;
  useEffect(() => {
    const interactionsCollectionRef = query(
      collection(db, "sessions", sessionId, "cards", cardId, "interactions"),
      card.settings?.outputResultsShown === "approved"
        ? where("approved", "==", true)
        : undefined,
      orderBy("createdAt", "desc")
    );
    return onSnapshot(interactionsCollectionRef, (snapshot) => {
      const newResponses = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (!compareArrayWithUpdatedAt(responses, newResponses)) {
        console.log({ newResponses });
        setResponses(newResponses);
      }
    });
  }, [sessionId, cardId]);

  const viewMode = card.settings?.outputViewMode;

  // console.log({ interactions });
  if (viewMode === "word-cloud") {
    return (
      <div className="prompt-output word-cloud">
        <WordcloudOutput
          words={responses.reduce(
            (acc, response) => [...acc, response.response.toLowerCase()],
            []
          )}
        />
      </div>
    );
  }

  return (
    <div className="prompt-output">
      {viewMode === "summary" && (
        <div className="prompt-response" key={"summary"}>
          <div className="displayName">Summary</div>
          <div className="response">{card?.responseSummary}</div>
        </div>
      )}
      {viewMode === "list" &&
        responses.slice(0, props.max).map((interaction) => {
          return (
            <div className="prompt-response" key={interaction.id}>
              <div className="displayName">{interaction.displayName}</div>
              <div className="response">{interaction.response}</div>
            </div>
          );
        })}
      {viewMode === "single" &&
        card.activeInteractions &&
        responses
          .filter((interaction) =>
            card.activeInteractions.includes(interaction.id)
          )
          .map((interaction) => {
            return (
              <div className="prompt-response" key={interaction.id}>
                <div className="displayName">{interaction.displayName}</div>
                <div className="response">{interaction.response}</div>
              </div>
            );
          })}
    </div>
  );
};

export default PromptOutput;
